import React, { useMemo } from 'react';
import { Headline } from '../Headline';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '../Paragraph';
import { Icon, iconNames } from '../Icon/Icon';

export const Footer: React.FC = () => {
  const { t } = useTranslation();

  type socialLink = {
    name: typeof iconNames[number];
    target: string;
  };

  const socialLinks = useMemo<socialLink[]>(
    () => [
      { name: 'Facebook', target: 'https://www.facebook.com/tigacall' },
      { name: 'Instagram', target: 'https://www.instagram.com/tigacall/' },
    ],
    [],
  );

  const footerLinks = useMemo(
    () => [
      { text: t('FOOTER_FAQ'), target: 'https://www.tigacall.de/faqs.html' },
      { text: t('SUPPORT'), target: 'mailto:support@tigacall.de' },
      { text: t('PRIVACY'), target: 'https://www.tigacall.de/privacy.html' },
      { text: t('IMPRINT'), target: 'https://www.tigacall.de/imprint.html' },
      { text: t('AGB'), target: 'https://www.tigacall.de/terms.html' },
    ],
    [],
  );
  return (
    <div className="text-center mt-xxxl">
      <div data-testid="social-links" className="flex space-x-l justify-center">
        {socialLinks.map((socialLink, index) => (
          <a
            target="_blank"
            rel="noreferrer"
            href={socialLink.target}
            key={index}
          >
            <Icon name={socialLink.name} color="dark" width={32} />
          </a>
        ))}
      </div>
      <ul
        data-testid="footer-links"
        className="md:flex justify-center md:space-x-l mt-l"
      >
        {footerLinks.map((link, index) => (
          <li className="mb-3 md:mb-0" key={index}>
            <a target="_blank" rel="noreferrer" href={link.target}>
              <Paragraph level="body-link" color="dark" text={link.text} />
            </a>
          </li>
        ))}
      </ul>
      <div className="mt-m max-w-xxs mx-auto mt-cl">
        <Headline level="h3" text={`© 2020 ${t('COPYRIGHT')}`} color="blue" />
      </div>
      <div className="bg-blue-primary h-xs mt-m"></div>
    </div>
  );
};
