import React, { useEffect, useRef, useState } from 'react';

export interface PopupContainerProps {
  children?: React.ReactNode;
  open: boolean;
  onBlur?: () => void;
}

export const PopupContainer: React.FC<PopupContainerProps> = ({
  children,
  open,
  onBlur,
}: PopupContainerProps) => {
  const [showPopup, setShowPopup] = useState(open);
  const outsiteRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setShowPopup(open);
  }, [open]);

  return (
    <div
      className={`${
        showPopup ? 'block opacity-100' : 'hidden opacity-0'
      } transition-opacity`}
    >
      <div
        ref={outsiteRef}
        className="flex justify-center items-center fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-20 z-50"
        onClick={(event) => {
          if (onBlur && event.target === outsiteRef.current) onBlur();
        }}
      >
        <div className="w-auto m-2 h-auto bg-white shadow-m text-left text-grey-400 md:m-0">
          {children}
        </div>
      </div>
    </div>
  );
};
