import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { Footer } from '../../components/Footer';
import { Headline } from '../../components/Headline';
import { Navigation } from '../../components/Navigation';
import { Paragraph } from '../../components/Paragraph';
import { PaymentPlan, updateUser } from '../../services/authService';
import { useGlobalState } from '../../State';
import { PaymentOptionSelect } from './PaymentOptionSelect';
import { loadStripe } from '@stripe/stripe-js';
import {
  createStripeCustomer,
  createStripeSession,
} from '../../services/paymentService';
import tryNTimes from '../../utilities/retryAsync';
import Stripe from 'stripe';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
);

export const PaymentOptions: React.FC = () => {
  const { t } = useTranslation();
  const [{ user }, { setUser }] = useGlobalState();
  const [termsAccepted, setTermsAccepted] = useState(
    user ? user.termsConsent : false,
  );
  const [marketingAccepted, setMarketingAccepted] = useState(
    user ? user.marketingConsent : false,
  );
  const [error, setError] = useState<string | null>(null);
  const [
    selectedPaymentOption,
    setPaymentOption,
  ] = useState<null | PaymentPlan>(user?.paymentPlan ?? null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      user &&
      user.accessToken &&
      selectedPaymentOption !== null &&
      termsAccepted
    ) {
      try {
        const { id: customerId } = user.stripeId
          ? { id: user.stripeId }
          : await tryNTimes<Stripe.Response<Stripe.Checkout.Session>>(
              createStripeCustomer,
              [user.email, user.locale ?? 'en'],
              5,
            );

        const { id: sessionId } = (await tryNTimes(createStripeSession, [
          customerId,
          user.id,
          selectedPaymentOption,
        ])) as Stripe.Response<Stripe.Checkout.Session>;

        const stripe = await stripePromise;

        if (stripe && sessionId) {
          const newUser = {
            ...user,
            stripeId: customerId,
            paymentPlan: selectedPaymentOption,
            termsConsent: termsAccepted,
            marketingConsent: marketingAccepted,
          };

          await updateUser(newUser);
          setUser(newUser);

          stripe.redirectToCheckout({
            sessionId,
          });
        }
      } catch (error) {
        // TODO: Display Toast
        setError(error);
      }
    }
  };

  const errorMessage = error ? (
    <div className="mt-5 mx-1">
      <Paragraph level="small" text={t(error)} color="error" />
    </div>
  ) : null;

  return (
    <>
      <Navigation />
      <div className="container text-center">
        <div className="max-w-md mx-auto">
          <Headline level="h2" text={t('PAYMENT_HEADLINE')} color="blue" />
        </div>
        <div className="mt-m max-w-sm mx-auto">
          <Paragraph level="body" text={t('CHOOSE_PAYMENT')} color="dark" />
        </div>
        <div className="my-xxxl">
          <PaymentOptionSelect
            initialValue={user?.paymentPlan}
            onSelect={setPaymentOption}
          />
        </div>
        <Paragraph
          level="body"
          text={t('PAYMENT_TERMS_PROVIDER')}
          color="dark"
        />
        <div className="mt-s">
          <Paragraph
            level="body"
            text={t('PAYMENT_TERMS_DURATION')}
            color="dark"
          />
        </div>
        <form
          className="col-12 md:col-6 md:offset-3 mt-xl md:mt-xxxl mb-xxxl"
          onSubmit={handleSubmit}
        >
          <div className="mb-s md:mb-l text-left space-y-m">
            <Checkbox
              value={termsAccepted}
              onChange={(event) => setTermsAccepted(event.target.checked)}
            >
              <p className="font-sans text-grey-400 text-base leading-3">
                {t('PAYMENT_TERMS_ACCEPT')
                  .split('<>')
                  .map((fragment, index) => {
                    if (index === 0) {
                      return (
                        <span key={index}>
                          {fragment}{' '}
                          <a
                            className="text-blue-interactive underline"
                            href="https://www.tigacall.de/terms.html"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t('PAYMENT_TERMS')}
                          </a>
                        </span>
                      );
                    } else if (index === 1) {
                      return (
                        <span key={index}>
                          {fragment}{' '}
                          <a
                            className="text-blue-interactive underline"
                            href="https://www.tigacall.de/privacy.html"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t('PAYMENT_PRIVACY')}
                          </a>
                        </span>
                      );
                    } else {
                      return <span key={index}>{fragment}</span>;
                    }
                  })}
              </p>
            </Checkbox>
            <Checkbox
              value={marketingAccepted}
              onChange={(event) => setMarketingAccepted(event.target.checked)}
            >
              <Paragraph
                text={t('TERMS_MARKETING_ACCEPT')}
                color="dark"
                level="body"
              />
            </Checkbox>
          </div>
          {errorMessage}
          <Button
            level="primary"
            text={t(`PAYMENT_CONFIRM`)}
            type="submit"
            disabled={!termsAccepted || selectedPaymentOption === null}
          />
        </form>
      </div>
      <Footer />
    </>
  );
};
