import React, { useMemo } from 'react';
import { getBreakpointClasses, TableProps } from '.';
import { Card } from '../Card';
import { Paragraph } from '../Paragraph';
import { Tooltip } from '../Tooltip';

export { TableRow } from './TableRow';
export const Table: React.FC<TableProps> = ({
  heads = [],
  children,
  layout,
}: TableProps) => {
  const tableHead = useMemo(
    () =>
      heads
        ? heads.map(({ text, tooltip }, index) => (
            <div
              key={index}
              className={`${
                index === 0 ? 'lg:text-left' : 'lg:text-right'
              } hidden lg:block relative ${
                layout ? getBreakpointClasses(index, layout) : null
              }`}
            >
              <Paragraph level="small" color="dark" text={text} />{' '}
              <div
                key={index}
                className={`${
                  index === 0 ? 'hidden' : 'block'
                } absolute top-0 right-0 -mt-1.5 -mr-4 tooltip-button z-10`}
              >
                <Tooltip tooltip={tooltip} />
              </div>
            </div>
          ))
        : null,
    [heads, layout],
  );

  return (
    <div className="mt-m mb-l">
      <div className="row">
        <div className="col-11">
          <div className="row px-xl mb-s">{tableHead}</div>
        </div>
      </div>
      <Card>{children}</Card>
    </div>
  );
};
