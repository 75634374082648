import React, { useState } from 'react';
import { Paragraph } from '../Paragraph';

type RadioProps = {
  name: string;
  initialSelection?: string;
  options: {
    id: string;
    label: string;
    value: string;
  }[];
  onSelect?: (value: string) => void;
};

export const Radio: React.FC<RadioProps> = ({
  name,
  initialSelection,
  options,
  onSelect,
}: RadioProps) => {
  const [value, setValue] = useState<string | null>(initialSelection ?? null);
  // const selectListRef = useRef<HTMLUListElement | null>(null);

  return (
    <div className="flex space-x-xxl">
      {options.map((option, index) => (
        <div key={index} className="flex items-center">
          <div
            className={`border-2 rounded-full w-4.5 h-4.5 ${
              value === option.value
                ? 'border-blue-interactive'
                : 'border-grey-200'
            }  text-grey-200 fill-current flex flex-shrink-0 justify-center items-center mr-2 focus-within:text-blue-interactive focus-within:border-blue-interactive`}
          >
            <input
              className="absolute opacity-0 cursor-pointer"
              type="radio"
              id={option.id}
              name={name}
              value={option.value}
              checked={value === option.value}
              onChange={(event) => {
                setValue(event.target.value);
                if (onSelect) onSelect(event.target.value);
              }}
            />
            <div
              data-testid="checkmark"
              className="checked fill-current hidden w-4 h-3 m-xxs rounded-full pointer-events-none bg-blue-interactive" //checked class is neeeded for additional css
            />
          </div>
          <label htmlFor={option.id} className="cursor-pointer">
            <Paragraph color="dark" level="body" text={option.label} />
          </label>
        </div>
      ))}
    </div>
  );
};
