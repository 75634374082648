import { getAccessToken, PaymentPlan } from './authService';
import Stripe from 'stripe';

export const createStripeSession: (
  customerId: string,
  userId: string,
  paymentPlan: PaymentPlan,
) => Promise<Stripe.Response<Stripe.Checkout.Session>> = async (
  customerId,
  userId,
  paymentPlan,
) => {
  const accessToken = await getAccessToken();
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_BASEURL}/user/create_checkout_session`,
    {
      method: 'post',
      headers: new Headers({
        Authorization: accessToken,
      }),
      body: JSON.stringify({
        customerId,
        userId,
        paymentPlan,
      }),
    },
  );

  const data = await response.json();
  if (response.status !== 200) {
    throw new Error(`${response.status}: ${data.message}`);
  } else {
    return data;
  }
};

export const createStripeCustomer: (
  email: string,
  locale: 'de' | 'en',
) => Promise<Stripe.Response<Stripe.Checkout.Session>> = async (
  email,
  locale,
) => {
  const accessToken = await getAccessToken();
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_BASEURL}/user/create_customer`,
    {
      method: 'post',
      headers: new Headers({
        Authorization: accessToken,
      }),
      body: JSON.stringify({
        email,
        locale,
      }),
    },
  );

  const data = await response.json();
  if (response.status !== 200) {
    throw new Error(`${response.status}: ${data.message}`);
  } else {
    return data.message;
  }
};

export const updateStripeCustomer: (
  userId: string,
  customerid: string,
  email: string,
) => Promise<Stripe.Response<Stripe.Customer>> = async (
  userId,
  customerId,
  email,
) => {
  const accessToken = await getAccessToken();
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_BASEURL}/user/update_customer`,
    {
      method: 'post',
      headers: new Headers({
        Authorization: accessToken,
      }),
      body: JSON.stringify({
        userId,
        customerId,
        email,
      }),
    },
  );

  const data = await response.json();
  if (response.status !== 200) {
    throw new Error(`${response.status}: ${data.message}`);
  } else {
    return data.message;
  }
};

export const addCustomerInvoiceData: (
  customerid: string,
) => Promise<Stripe.Response<Stripe.Customer>> = async (customerId) => {
  const accessToken = await getAccessToken();
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_BASEURL}/user/add_customer_invoice_data`,
    {
      method: 'post',
      headers: new Headers({
        Authorization: accessToken,
      }),
      body: JSON.stringify({
        customerId,
      }),
    },
  );

  const data = await response.json();
  if (response.status !== 200) {
    console.log(data);
    throw new Error(`${response.status}: ${data.message}`);
  } else {
    return data.message;
  }
};

export const getStripeCustomerSubscription: (
  customerId: string,
) => Promise<Stripe.Subscription> = async (customerId) => {
  const accessToken = await getAccessToken();
  const response = await fetch(
    `${
      process.env.REACT_APP_BACKEND_BASEURL
    }/user/get_subscription?${new URLSearchParams({
      customer_id: customerId,
    })}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: accessToken,
      }),
    },
  );

  const data = await response.json();
  if (response.status !== 200) {
    throw new Error(`${response.status}: ${data.message}`);
  } else {
    return data.message;
  }
};

export const getStripePaymentMethod: (
  paymentId: string,
) => Promise<Stripe.PaymentMethod> = async (paymentId) => {
  const accessToken = await getAccessToken();
  const response = await fetch(
    `${
      process.env.REACT_APP_BACKEND_BASEURL
    }/user/get_payment_method?${new URLSearchParams({
      payment_id: paymentId,
    })}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: accessToken,
      }),
    },
  );

  const data = await response.json();
  if (response.status !== 200) {
    throw new Error(`${response.status}: ${data.message}`);
  } else {
    return data.message;
  }
};

export const getStripeBillingPortalSession: (
  customerId: string,
) => Promise<string> = async (customerId) => {
  const accessToken = await getAccessToken();
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_BASEURL}/user/create_billing_session`,
    {
      method: 'post',
      headers: new Headers({
        Authorization: accessToken,
      }),
      body: JSON.stringify({
        customerId,
      }),
    },
  );

  const data = await response.json();
  if (response.status !== 200) {
    throw new Error(`${response.status}: ${data.message}`);
  } else {
    return data.message;
  }
};
