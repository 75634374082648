import React, {
  ComponentType,
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { User } from './services/authService';

interface State {
  user: User | null;
}

const initialState: State = {
  user: null,
};

enum ActionType {
  SET_USER,
}

type Action = { type: ActionType.SET_USER; user: User | null };

interface actions {
  setUser: (user: User | null) => void;
}

const StateContext = createContext<[State, actions]>([
  initialState,
  {
    setUser: () => {},
  },
]);

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
};

type StateProviderProps = { children: React.ReactNode };
export const StateProvider: ComponentType<StateProviderProps> = ({
  children,
}: StateProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setloading] = useState(true);

  const actions = useMemo(() => {
    return {
      setUser: (user: User | null) => {
        localStorage.setItem('tigcall_user', JSON.stringify(user));
        dispatch({
          type: ActionType.SET_USER,
          user,
        });
      },
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const stringifiedUser = localStorage.getItem('tigcall_user');
      if (stringifiedUser) {
        const user = JSON.parse(stringifiedUser);
        actions.setUser(user);
      }

      setloading(false);
    })();
  }, [actions]);

  return (
    <StateContext.Provider value={[state, actions]}>
      {loading ? null : children}
    </StateContext.Provider>
  );
};

export const useGlobalState: () => [State, actions] = () => {
  return useContext(StateContext);
};
