import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { routes } from '../../routes';
import { signout } from '../../services/authService';
import { useGlobalState } from '../../State';

type MenuItemProps = {
  action?: string;
  href?: string;
  children: React.ReactNode;
  onPopupChange?: (isOpen: boolean) => void;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  action,
  href,
  children,
  onPopupChange,
}: MenuItemProps) => {
  const [, { setUser }] = useGlobalState();
  const history = useHistory();

  const item = () => {
    if (action) {
      switch (action) {
        case 'add':
          return (
            <button
              className="border-b-1 border-white md:border-0"
              onClick={() => {
                if (onPopupChange) {
                  onPopupChange(true);
                }
              }}
            >
              {children}
            </button>
          );
        case 'logout':
          return (
            <button
              className="border-0"
              onClick={async () => {
                setUser(null);
                await signout();
                history.push(routes.signin.path);
              }}
            >
              {children}
            </button>
          );
      }
    } else if (href) {
      return (
        <Link to={href} className="border-b-1 border-white md:border-0">
          {children}
        </Link>
      );
    }
  };

  return <>{item()}</>;
};
