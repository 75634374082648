import React, { useEffect, useState } from 'react';
import { Link } from '../../components/Link';
import { Button } from '../../components/Button';
import { Headline } from '../../components/Headline';
import { Input } from '../../components/Input';
import { routes } from '../../routes';
import { Paragraph } from '../../components/Paragraph';
import { Icon } from '../../components/Icon/Icon';
import { ToastMessage } from '../../components/ToastMessage';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import { TigaContainer } from '../../components/TigaContainer';
import { useHistory } from 'react-router-dom';
import {
  confirmSignUp,
  signin,
  signup,
  User,
} from '../../services/authService';
import { useGlobalState } from '../../State';
import { MultiStepForm } from '../../components/MultiStepForm';
import i18n from '../../i18n';

export const Signup: React.FC = () => {
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [signupError, setSignupError] = useState<string>('');
  const [showClearPassword, setShowClearPassword] = useState(false);
  const [currentFormStep, setCurrentFormStep] = useState(0);
  const [code, setCode] = useState<string | null>(null);
  const [localUser, setLocalUser] = useState<User | null>(null);
  const [showToast, setShowToast] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const [{ user }, { setUser }] = useGlobalState();

  useEffect(() => {
    if (firstName && lastName && email && password) {
      setLocalUser({
        id: '',
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        stripeId: '',
        locale: i18n.language ?? 'en',
        hasCompletedPayment: false,
        contactsSelected: 0,
        contactsTotal: 0,
      });
    } else {
      setLocalUser(null);
    }
  }, [firstName, lastName, email, password, i18n.language]);

  const handleSignupSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    if (localUser) {
      try {
        await signup(localUser);
        setCurrentFormStep((step) => step + 1);
      } catch (error) {
        setSignupError(error.message);
        setShowToast(true);
      }
    }
  };

  const handleConfirmSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();

    if (localUser && code) {
      try {
        await confirmSignUp(localUser?.email, code);
        const authenticatedUser = await signin(localUser);
        setUser(authenticatedUser);
      } catch (error) {
        setSignupError(error.message);
        setShowToast(true);
      }
    } else {
      setCurrentFormStep((step) => step - 1);
    }
  };

  useEffect(() => {
    // redirect only after global user is set
    if (user) history.push(routes.paymentOptions.path);
  }, [user]);

  return (
    <>
      <TigaContainer>
        <div className="my-120px md:my-0">
          <div className="w-120px md:w-240px block m-auto my-s">
            <Icon name="Logo" color="blue-primary" />
          </div>
          <div className="text-center">
            <Headline level="h2" color="blue" text={t(`SIGNUP`)} />
          </div>
        </div>
        <div>
          <MultiStepForm
            currentStep={currentFormStep}
            steps={[
              <form key={1} onSubmit={handleSignupSubmit}>
                <div className="space-y-4">
                  <div className="row mt-6">
                    <div className="col-6">
                      <Input
                        color="blue"
                        type="text"
                        label={t(`FIRSTNAME`)}
                        id="signup_first_name"
                        autoComplete="given-name"
                        onChange={(event) => setFirstName(event.target.value)}
                        required
                      />
                    </div>
                    <div className="col-6">
                      <Input
                        color="blue"
                        type="text"
                        label={t(`LASTNAME`)}
                        id="signup_last_namee"
                        autoComplete="family-name"
                        onChange={(event) => setLastName(event.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Input
                        color="blue"
                        type="email"
                        label={t(`EMAIL`)}
                        id="signup_email"
                        autoComplete="email"
                        onChange={(event) => setEmail(event.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row relative mt-2">
                    <div className="col-12 whitespace-pre-wrap">
                      <Input
                        color="blue"
                        type={showClearPassword ? 'text' : 'password'}
                        label={t('PASSWORD')}
                        caption={t(`PASSWORD_REQUIREMENTS`, {
                          returnObjects: true,
                        })}
                        id="signin_password"
                        autoComplete="current-password"
                        passwordValidation={true}
                        onChange={(event) => setPassword(event.target.value)}
                        required
                      />
                    </div>

                    <div className="absolute top-0.5 right-xl">
                      <Button
                        tabIndex={-1}
                        level="icon"
                        type="button"
                        icon="Password"
                        iconColor="light"
                        onClick={() => setShowClearPassword(!showClearPassword)}
                      />
                    </div>
                  </div>
                  <div className="mt-5">
                    <Button level="primary" text={t(`SIGNUP`)} type="submit" />
                  </div>
                </div>
                <div className="col-12 text-center mt-s">
                  <Link to={routes.signin.path}>
                    <Paragraph
                      level="link"
                      color="blue"
                      text={t(`ALREADY_SIGNED_IN`)}
                    />
                  </Link>
                </div>
              </form>,
              <form
                key={2}
                className="space-y-4"
                onSubmit={handleConfirmSubmit}
              >
                {/* step 2 */}
                <div className="row mt-6 whitespace-pre-wrap">
                  <div className="col-12">
                    <div className="mb-6">{t('RESET_CODE_EXPLANATION')}</div>
                    <Input
                      color="blue"
                      type="numbers"
                      label={t('RESET_CODE')}
                      id="resetPin"
                      maxLength={6}
                      autoComplete="email"
                      onChange={(event) => setCode(event.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="mt-5">
                  <Button level="primary" text={t('CONFIRM')} type="submit" />
                </div>
              </form>,
            ]}
          />
        </div>
      </TigaContainer>
      <ToastMessage
        isVisible={showToast}
        type="error"
        headline={t('SIGNUP_ERROR_HEADLINE')}
        text={t(signupError)}
        onToggle={(state) => {
          setShowToast(state);
        }}
        timeout={true}
      ></ToastMessage>
    </>
  );
};
