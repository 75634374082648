import React, { useCallback } from 'react';

export type HeadlineLevels = 'h1' | 'h2' | 'h3' | 'h4';
export type HeadlineProps = {
  level: HeadlineLevels;
  color: 'blue' | 'dark' | 'light' | 'white';
  text: string;
};

export const Headline: React.FC<HeadlineProps> = ({
  level,
  color,
  text,
}: HeadlineProps) => {
  const renderHeadline = useCallback(() => {
    const styles = ['font-sans', 'uppercase', 'break-words'];
    switch (color) {
      case 'blue':
        styles.push('text-blue-primary');
        break;
      case 'dark':
        styles.push('text-grey-400');
        break;
      case 'light':
        styles.push('text-grey-200');
        break;
      case 'white':
        styles.push('text-white');
        break;
    }

    switch (level) {
      case 'h1':
        styles.push('font-bold text-lg md:text-xl leading-10');
        return <h1 className={styles.join(' ')}>{text}</h1>;
      case 'h2':
        styles.push('font-medium text-lg leading-6.5');
        return <h2 className={styles.join(' ')}>{text}</h2>;
      case 'h3':
        styles.push('font-bold text-md leading-5');
        return <h3 className={styles.join(' ')}>{text}</h3>;
      case 'h4':
        styles.push('font-bold text-xxs leading-1');
        return <h4 className={styles.join(' ')}>{text}</h4>;
    }
  }, [level, color, text]);

  return <>{renderHeadline()}</>;
};
