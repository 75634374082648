export const en = {
  translation: {
    welcome: 'Welcome, Tiga!',
    LOGOUT: 'Logout',
    FAQ: 'Help and support',
    PRIVACY: 'Data privacy',
    IMPRINT: 'Imprint',
    FOOTER_FAQ: 'FAQ',
    SUPPORT: 'Request support',
    AGB: 'T&C',
    SIGNIN: 'Login',
    ALREADY_SIGNED_IN: 'Already have an account?',
    SIGNUP: 'Sign up',
    SIGNUP_BACK: 'Back to Signup',
    SIGNUP_CTA: 'Register now!',
    FIRSTNAME: 'First Name',
    LASTNAME: 'Last Name',
    STAY_SIGNEDIN: 'Stay signed in',
    EMAIL: 'E-Mail',
    PASSWORD: 'Password',
    NEW_PASSWORD: 'New Password',
    PASSWORD_FORGOT: 'Password forgotten',
    PASSWORD_FORGOT_CTA: 'Forgot you password?',
    PASSWORD_RESET: 'Reset',
    PASSWORD_REQUIREMENTS: [
      'Min. 8 characters',
      'Upper and lower case letters',
      'Numbers and special characters',
    ],
    COPYRIGHT: 'Tigacall GmbH',
    PAYMENT_HEADLINE:
      "Just a few more steps and you're part of the Tigacall family",
    CHOOSE_PAYMENT:
      'Please select your preferred payment plan. Afterwards, you will be redirected to our payment processer.',
    PAYMENT_NAME_MONTHLY: 'Monthly payment',
    PAYMENT_PRICE_MONTHLY: '49.00 €',
    PAYMENT_INTERVAL_MONTHLY: 'Month',
    PAYMENT_CAPTION_MONTHLY:
      'The total price including statutory VAT at the current rate of 19% is € 588.00 (12 monthly payments of € 49.00).',
    PAYMENT_NAME_HALFYEARLY: 'Half-yearly payment',
    PAYMENT_PRICE_HALFYEARLY: '46.55 €',
    PAYMENT_INTERVAL_HALFYEARLY: 'Month',
    PAYMENT_CAPTION_HALFYEARLY:
      'The total price including statutory VAT at the current rate of 19% is € 558.60 (2 half-yearly payments of € 279.30 €).',
    PAYMENT_NAME_YEARLY: 'Yearly payment',
    PAYMENT_PRICE_YEARLY: '44.10 €',
    PAYMENT_INTERVAL_YEARLY: 'Month',
    PAYMENT_CAPTION_YEARLY:
      'The total price including statutory VAT at the current rate of 19% is € 529.20 (yearly one-time payment).',
    PAYMENT_TERMS_PROVIDER:
      'The billing of our license fee takes place via Stripe Billing. VISA, Mastercard or American Express credit cards are accepted. After clicking the "order now with obligation to pay" button at the end of this text, you will be redirected to the interface of our payment service provider, where you please provide the credit card information required.',
    PAYMENT_TERMS_DURATION:
      'The 12-month minimum contract period begins after you receive our confirmation e-mail (see Terms of Use: 4. Conclusion of the Contract) and is automatically extended by a further 12 months if the contract is not terminated at least 30 days before the end of the contract year (see Conditions of Use: 7 Duration and Termination of the Contract).',
    PAYMENT_TERMS_ACCEPT: 'I have read and accept the <> and <>',
    PAYMENT_TERMS: 'Terms of Use',
    PAYMENT_PRIVACY: 'Data Privacy Statement',
    TERMS_MARKETING_ACCEPT:
      'Yes, I want to receive Marketing e-mails from Tigacall',
    PAYMENT_CONFIRM: 'Subscribe now with obligation to pay',
    MENU_TOGGLE_OPEN: 'Open navigation.',
    MENU_TOGGLE_CLOSE: 'Close navifation.',
    INVITE_TIGA: 'Invite Tiga',
    PROFIL: 'Profile',
    TIGA_FAMILY_HEADLINE: 'Your Tiga-Family',
    TIGA_FAMILY_SEARCH: 'Member search',
    NAME: 'Name',
    SELECTION: 'Selection',
    CALLS: 'Calls',
    APPOINTMENTS: 'Sales Meetings',
    APPOINTMENTS_PLANNED: 'Scheduled Meetings',
    CONTACTS_REACHED: 'Reached Contacts',
    SIGNINGS: 'Closings',
    BACK_TO_DASHBOARD: 'Back to overview',
    TIGAPROFIL: 'Your Tiga-Profile',
    EDIT_PROFILE: 'Edit profile',
    ABONNEMENT: 'Subscription',
    MEMBER_SINCE: 'Tiga since',
    NEXT_PAYMENT: 'Next payment',
    PAYMENT_METHOD: 'Payment method',
    EDIT_SUBSCRIPTION: 'Edit subscription',
    SAVE: 'Save',
    VALIDATION_FIELD_EMPTY: 'This field cannot be empty',
    VALIDATION_FIELD_EMAIL: 'This is not a valid e-mail addres',
    VALIDATION_FIELD_PASSWORD: [
      'Min. 8 characters',
      'Upper and lower case letters',
      'Numbers and special characters',
    ],
    NotAuthorizedException:
      'The entered combination of e-mail and password is not correct.',
    PasswordResetUserNotFoundException:
      'There is no account connected to this e-mail address',
    UserNotFoundException:
      'There is no account connected to this e-mail address',
    UsernameExistsException:
      'There is already an account with this e-mail address. Please either signin or use the password forgot functionality.',
    CodeMismatchException: 'The entered code is not correct.',
    RESET_CODE_EXPLANATION:
      'We will send you an e-mail shortly containing a 6-digit code. Please enter that code into the input field below to verfify your e-mail address',
    RESET_CODE: 'Code',
    RESET_SUCCESS: 'Your password was successfully updated.',
    PasswordResetInvalidParameterException:
      'You need to verify your e-mail address before being able to reset your password',
    CONFIRM: 'confirm',
    UserNotConfirmedException:
      'You need to confirm your e-mail address before beeing able to sign in.',
    TOOLTIP_ARIA: 'Tooltip',
    TOOLTIP_SELECTION:
      'The daily updated number of contacts selected for Tigacall.',
    TOOLTIP_CALLS:
      'This is the number of calls made in the selected time period.\n\nThe ratio describes the number of calls made in relation to the daily pending calls (10 per day).',
    TOOLTIP_APPOINTMENTS:
      'This is the number of sales meetings that took place in the selected time period.',
    TOOLTIP_SIGNINGS:
      'The number of closings made in the sales meetings\n\nThe ratio describes the number of closings in relation to the conducted sales meetings.',
    TOOLTIP_APPOINTMENTS_PLANNED:
      'The number of scheduled sales meetings (in person or via phone).\n\nThe ratio describes the number of scheduled meetings in relation to reached contacts.',
    TOOLTIP_CONTACTS_REACHED:
      'The number of contacts for whom a (phone) meeting was scheduled or who declined the consultation.\n\nThe ratio describes the number of reached contacts to made calls.',
    SUCCESS_HEADLINE: 'Tiga added succesfully',
    SUCCESS_BODY:
      'Maximilian Bauer was added to your Tiga-family successfully.',
    PASSWORD_RESET_ERROR_HEADLINE: 'Password change failed',
    SIGNIN_ERROR_HEADLINE: 'Login failed.',
    SIGNUP_ERROR_HEADLINE: 'Registration failed.',
    INVITE_TIGA_POPUP_HEAD: 'Invite a Tiga to your structure.',
    INVITE_TIGA_POPUP_BODY:
      'Please enter the mail adress of the Tiga you want to invite to your structure:',
    REMOVE_TIGA_POPUP_HEAD: 'Remove Tiga',
    REMOVE_TIGA_POPUP_BODY:
      'Do you really want to remove this Tiga from your structure?',
    INVITE: 'Invite',
    REMOVE: 'Remove',
    CANCEL: 'Cancel',
    APPLY: 'Apply',
    SEARCHBAR_PLACEHOLDER: 'Member search',
    NO_ENTRIES: 'No entries found.',
    PROFILE_SAVE_SUCCESS_HEADLINE: 'Profile saved',
    PROFILE_SAVE_SUCCESS_TEXT: 'Your changes have been saved successfully.',
    PROFILE_SAVE_ERROR_HEADLINE: 'Error',
    PROFILE_SAVE_ERROR_TEXT:
      'An error occurred when saving your profile. Please try again later.',
    INVITE_NOT_FOUND: 'There is no user with such an e-mail address.',
    DASHBOARD_FETCH_DATA_ERROR_HEADLINE: 'Error fetching data',
    DASHBOARD_FETCH_DATA_ERROR_TEXT:
      'Your dashboard data could not be fetched. Please try again later.',
    DASHBOARD_INVITE_SUCCESS_HEADLINE: 'Inviting Tiga',
    DASHBOARD_INVITE_SUCCESS_TEXT:
      'We have sent an invitation e-mail to the specified e-mail address',
    DASHBOARD_INVITE_ERROR_HEADLINE: 'Error inviting Tiga.',
    DASHBOARD_INVITE_ERROR_TEXT:
      'Something went wrong while inviting the user.',
    DASHBOARD_INVITE_NOTFOUND_TEXT: 'The invited account does not exist',
    DASHBOARD_INVITE_ACCEPTED_HEADLINE: 'Invitation accepted',
    DASHBOARD_INVITE_ACCEPTED_TEXT:
      'You successfully accepted the invitation to join the structure',
    DASHBOARD_INVITE_DECLINED_HEADLINE: 'Invitation declined',
    DASHBOARD_INVITE_DECLINED_TEXT:
      'You have declined the invitation to join the structure',
    PAYMENT_CONFIRM_HEADLINE: 'One Moment',
    PAYMENT_CONFIRM_TEXT: 'Waiting for payment confirmation.',
    LANGUAGE: 'Language',
    GERMAN: 'German',
    ENGLISH: 'English',
    card: 'Credit Card', // supposed to be lowercase
    PROFILE_PAYMENTDATA_RETRIVE_ERROR_HEADLINE:
      'Error retrieving your payment information.',
    PROFILE_PAYMENTDATA_RETRIVE_ERROR_TEXT: 'Please try again later',
    SUBSCRIPTION_CANCELED_AT: 'Subscription will be canceled at',
    REMOVE_TIGA_SUCCESS_HEADLINE: 'Removed successfully',
    REMOVE_TIGA_SUCCESS_TEXT: 'This Tiga will no longer be part of you family.',
    REMOVE_TIGA_ERROR_HEADLINE: 'Error removing Tiga',
    REMOVE_TIGA_ERROR_TEXT: 'Please try again later',
    LimitExceededException:
      'Attempt limit exceeded, please try after some time.',
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    THIS_WEEK: 'This week',
    LAST_WEEK: 'Last week',
    THIS_MONTH: 'This month',
    LAST_MONTH: 'Last month',
  },
};
