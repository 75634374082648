import React from 'react';
import { Router } from './Router';
import { BrowserRouter } from 'react-router-dom';
import { StateProvider } from './State';

const App: React.FC = () => {
  return (
    <StateProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </StateProvider>
  );
};

export default App;
