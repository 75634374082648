/* eslint-disable @typescript-eslint/no-explicit-any */
async function tryNTimes<T>(
  toTry: (...args: any[]) => Promise<T & { [key: string]: any }>, //generic type but definetly an object
  toTryArguments?: any[],
  times = 5,
  condition?: { key: string; value: unknown },
): Promise<T> {
  let attemptCount = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      const result = await toTry(...(toTryArguments ?? []));
      if (condition && result && result[condition.key] !== condition.value) {
        throw new Error(
          `tryied ${attemptCount} times, but condition does not apply`,
        );
      }
      return result;
    } catch (error) {
      if (++attemptCount >= times) throw error;
    }
  }
}

export default tryNTimes;
