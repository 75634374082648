export const de = {
  translation: {
    WELCOME: 'Willkommen, Tiga!',
    LOGOUT: 'Logout',
    FAQ: 'Hilfe und Support',
    FOOTER_FAQ: 'FAQ',
    PRIVACY: 'Datenschutz',
    IMPRINT: 'Impressum',
    SUPPORT: 'Support anfragen',
    AGB: 'AGB',
    SIGNIN: 'Login',
    ALREADY_SIGNED_IN: 'Zurück zum Login',
    SIGNUP: 'Registrieren',
    SIGNUP_BACK: 'Zurück zum Login',
    SIGNUP_CTA: 'Registrier dich jetzt!',
    FIRSTNAME: 'Vorname',
    LASTNAME: 'Nachname',
    STAY_SIGNEDIN: 'Angemeldet bleiben',
    EMAIL: 'E-Mail',
    PASSWORD: 'Passwort',
    NEW_PASSWORD: 'Neues Passwort',
    PASSWORD_FORGOT: 'Passwort vergessen',
    PASSWORD_FORGOT_CTA: 'Passwort vergessen?',
    PASSWORD_RESET: 'Zurücksetzen',
    PASSWORD_REQUIREMENTS: [
      'Min. 8 Zeichen',
      'Groß- und Kleinbuchstaben',
      'Ziffern und Sonderzeichen',
    ],
    COPYRIGHT: 'Tigacall GmbH',
    PAYMENT_HEADLINE:
      'Nur noch wenige Schritte und Du bist Teil der Tigacall-Familie',
    CHOOSE_PAYMENT:
      'Bitte wähle hier dein bevorzugtes Abrechnungsintervall. Im Anschluß wirst du an unseren Zahlungsanbieter weitergeleitet.',
    PAYMENT_NAME_MONTHLY: 'Monatliche Zahlung',
    PAYMENT_PRICE_MONTHLY: '49,00 €',
    PAYMENT_INTERVAL_MONTHLY: 'Monat',
    PAYMENT_CAPTION_MONTHLY:
      'Der Gesamtpreis inkl. gesetzlicher USt. in derzeitiger Höhe von 19 % beträgt 588,00 € (12 Monatsbeträge à 49,00 €).',
    PAYMENT_NAME_HALFYEARLY: 'Halbjährliche Zahlung',
    PAYMENT_PRICE_HALFYEARLY: '46,55 €',
    PAYMENT_INTERVAL_HALFYEARLY: 'Monat',
    PAYMENT_CAPTION_HALFYEARLY:
      'Der Gesamtpreis inkl. gesetzlicher USt. in derzeitiger Höhe von 19 % beträgt 558,60 € (2 Halbjahresbeträge à 279,30 €).',
    PAYMENT_NAME_YEARLY: 'Jährliche Zahlung',
    PAYMENT_PRICE_YEARLY: '44,10 €',
    PAYMENT_INTERVAL_YEARLY: 'Monat',
    PAYMENT_CAPTION_YEARLY:
      'Der Gesamtpreis inkl. gesetzlicher USt. in derzeitiger Höhe von 19 % beträgt 529,20 € (jährliche Einmalzahlung).',
    PAYMENT_TERMS_PROVIDER:
      'Die Abrechnung unserer Lizenzgebühr erfolgt über Stripe Billing, wobei die Kreditkarten von VISA, Mastercard oder American Express akzeptiert werden. Nach Betätigen des „Jetzt zahlungspflichtig bestellen“-Buttons am Ende dieses Textes wird das Interface unseres Zahlungsdienstleisters aufgerufen, wo du bitte die für dessen Kreditkartenabrechnung erforderlichen Angaben machst.',
    PAYMENT_TERMS_DURATION:
      'Die 12-monatige Mindestvertragslaufzeit beginnt mit Eingang unserer Bestätigungsmail (siehe Nutzungsbedingungen: 4. Vertragsschluss) und verlängert sich automatisch jeweils um weitere 12 Monate, wenn der Vertrag nicht spätestens 30 Tage vor Ende des Vertragsjahres gekündigt wird (siehe Nutzungsbedingungen: 7. Vertragsdauer und Kündigung).',
    PAYMENT_TERMS_ACCEPT:
      'Ich habe die <> sowie die <> gelesen und stimme diesen zu.',
    PAYMENT_TERMS: 'Nutzungsbedingungen',
    PAYMENT_PRIVACY: 'Datenschutzerklärung',
    TERMS_MARKETING_ACCEPT:
      'Ja, Tigacall darf mir zu Marketing-Zwecken E-Mails senden.',
    PAYMENT_CONFIRM: 'Jetzt zahlungspflichtig bestellen',
    MENU_TOGGLE_OPEN: 'Navigation öffnen.',
    MENU_TOGGLE_CLOSE: 'Navigation schließen.',
    INVITE_TIGA: 'Tiga einladen',
    PROFIL: 'Profil',
    TIGA_FAMILY_HEADLINE: 'Deine Tiga-Familie',
    TIGA_FAMILY_SEARCH: 'Mitglieder-Suche',
    NAME: 'Name',
    SELECTION: 'Selektion',
    CALLS: 'Anrufe',
    APPOINTMENTS: 'Termine',
    APPOINTMENTS_PLANNED: 'Vereinbarte Termine',
    CONTACTS_REACHED: 'Erreichte Kontakte',
    SIGNINGS: 'Abschlüsse',
    BACK_TO_DASHBOARD: 'Zurück zur Übersicht',
    TIGAPROFIL: 'Dein Tiga-Profil',
    EDIT_PROFILE: 'Profil bearbeiten',
    ABONNEMENT: 'Abonnement',
    MEMBER_SINCE: 'Tiga seit',
    NEXT_PAYMENT: 'Nächste Zahlung',
    PAYMENT_METHOD: 'Zahlungsmethode',
    EDIT_SUBSCRIPTION: 'Abonnement bearbeiten',
    SAVE: 'Speichern',
    VALIDATION_FIELD_EMPTY: 'Dieses Feld darf nicht leer sein',
    VALIDATION_FIELD_EMAIL: 'Dies ist keine valide Email Adresse',
    VALIDATION_FIELD_PASSWORD: [
      'Min. 8 Zeichen',
      'Groß- und Kleinbuchstaben',
      'Ziffern und Sonderzeichen',
    ],
    NotAuthorizedException: 'Email Adresse oder Passwort inkorrekt.',
    PasswordResetUserNotFoundException:
      'Es konnte kein Account mit dieser Email Adresse gefunden werden.',
    UserNotFoundException:
      'Es konnte kein Account mit dieser Email Adresse gefunden werden.',
    UsernameExistsException:
      'Es existiert bereits ein Account mit dieser Email Adresse. Bitte benutzen sie die Signin oder Passwort Vergessen Funktionalität.',
    CodeMismatchException: 'Der eingegebene Code ist inkorrekt.',
    RESET_CODE_EXPLANATION:
      'Fast geschafft!\n\nWir schicken dir eine Email mit einem Code zu. Bitte übertrage diesen Code in das folgende Feld und lege dein neues Passwort fest.',
    RESET_CODE: 'Code',
    RESET_SUCCESS: 'Dein neues Password wurde erfolgreich gespeichert.',
    PasswordResetInvalidParameterException:
      'Sie müssen ihre Email Adresse verifizieren, bevor Sie ihr Passwort zurücksetzen können',
    CONFIRM: 'Bestätigen',
    UserNotConfirmedException:
      'Sie müssen ihre Email Adresse verifizieren, bevor Sie sich einloggen können.',
    TOOLTIP_ARIA: 'Tooltip',
    TOOLTIP_SELECTION:
      'Die tagesaktuelle Anzahl der für Tigacall ausgewählten Kontakte aus dem Adressbuch.',
    TOOLTIP_CALLS:
      'Dies ist die Anzahl aller getätigten Anrufe im ausgewählten Zeitraum.\n\nDie Quote beschreibt das Verhältnis getätigte Anrufe zu den täglich anstehenden Anrufen (10 pro Tag)',
    TOOLTIP_APPOINTMENTS:
      'Die Anzahl stattgefundener Termine im ausgewählten Zeitraum.',
    TOOLTIP_SIGNINGS:
      'Die Anzahl der erfolgten Abschlüsse aus den stattgefundenen Terminen.\n\nDie Quote beschreibt das Verhältnis der Abschlüsse in Relation zu den stattgefundenen Terminen.',
    TOOLTIP_APPOINTMENTS_PLANNED:
      'Die Anzahl aller vereinbarten persönlichen oder telefonischen Termine.\n\nDie Quote beschreibt das Verhältnis der vereinbarten Termine zu den erreichten Kontakten.',
    TOOLTIP_CONTACTS_REACHED:
      'Die Anzahl aller Kontakte, für die entweder ein (telefonischer) Termin vereinbart wurde, oder die die Beratung abgelehnt haben.\n\nDie Quote beschreibt das Verhältnis der erreichten Kontakte zu den getätigten Anrufen.',
    SUCCESS_HEADLINE: 'Tiga erfolgreich hinzugefügt',
    SUCCESS_BODY:
      'Maximilian Bauer wurde erfolgreich zu deiner Tiga-Family hinzugefügt.',
    PASSWORD_RESET_ERROR_HEADLINE: 'Änderung des Passworts fehlgeschlagen',
    SIGNIN_ERROR_HEADLINE: 'Login fehlgeschlagen.',
    SIGNUP_ERROR_HEADLINE: 'Registrierung fehlgeschlagen.',
    INVITE_TIGA_POPUP_HEAD: 'Lade einen Tiga in deine Struktur ein.',
    INVITE_TIGA_POPUP_BODY:
      'Bitte gebe die E-Mail Adresse des Tigas ein, den Du in Deine Struktur einladen möchtest:',
    REMOVE_TIGA_POPUP_HEAD: 'Diesen Tiga entfernen',
    REMOVE_TIGA_POPUP_BODY:
      'Möchtest du diesen Tiga wirklich aus deiner Struktur entfernen?',
    INVITE: 'Einladen',
    REMOVE: 'Entfernen',
    CANCEL: 'Abbrechen',
    APPLY: 'Anwenden',
    SEARCHBAR_PLACEHOLDER: 'Mitgliedersuche',
    NO_ENTRIES: 'Keine Einträge gefunden.',
    PROFILE_SAVE_SUCCESS_HEADLINE: 'Profil gespeichert',
    PROFILE_SAVE_SUCCESS_TEXT:
      'Deine Änderungen wurden erfolgreich gespeichert.',
    PROFILE_SAVE_ERROR_HEADLINE: 'Fehler',
    PROFILE_SAVE_ERROR_TEXT:
      'Beim Speichern deiner Änderungen ist leider ein Fehler aufgetreten. Bitte versuche es später noch einmal',
    INVITE_NOT_FOUND: 'Es existiert kein Nutzer mit dieser Email Adresse.',
    DASHBOARD_FETCH_DATA_ERROR_HEADLINE: 'Daten konnten nicht geladen werden.',
    DASHBOARD_FETCH_DATA_ERROR_TEXT: 'Bitte versuche es später noch einmal',
    DASHBOARD_INVITE_SUCCESS_HEADLINE: 'Tiga eingeladen',
    DASHBOARD_INVITE_SUCCESS_TEXT:
      'Wir haben soeben eine Einladungs-Email verschickt. Sobald diese angenommen wird, kannst du den entsprechenden Account auf deinem Dashboard sehen.',
    DASHBOARD_INVITE_ERROR_HEADLINE: 'Einladen fehlgeschlagen',
    DASHBOARD_INVITE_ERROR_TEXT:
      'Die Einladung des Accounts war leider nicht erfolgreich.',
    DASHBOARD_INVITE_NOTFOUND_TEXT:
      'Es konnte kein Account mit diese Email Adresse gefudnen werden.',
    DASHBOARD_INVITE_ACCEPTED_HEADLINE: 'Einladung Angenommen.',
    DASHBOARD_INVITE_ACCEPTED_TEXT:
      'Du befindest dich jetzt in der Struktur des angemommenen Accounts',
    DASHBOARD_INVITE_DECLINED_HEADLINE: 'Einladung abgelehnt',
    DASHBOARD_INVITE_DECLINED_TEXT:
      'Fordere eine neue Einladung an, falls du versehentlich auf Ablehnen geklickt hast.',
    PAYMENT_CONFIRM_HEADLINE: 'Einen Moment bitte',
    PAYMENT_CONFIRM_TEXT:
      'Warten auf Zahlungsbestätigung. Die Weiterleitung erfolgt automatisch.',
    LANGUAGE: 'Sprache',
    GERMAN: 'Deutsch',
    ENGLISH: 'Englisch',
    card: 'Kreditkarte', // supposed to be lowercase
    PROFILE_PAYMENTDATA_RETRIVE_ERROR_HEADLINE:
      'Fehler beim Abrufen ihrer Zahlungsinformationen',
    PROFILE_PAYMENTDATA_RETRIVE_ERROR_TEXT:
      'Bitte versuchen sie es später noch einmal.',
    SUBSCRIPTION_CANCELED_AT: 'Abo gekündigt zum',
    REMOVE_TIGA_SUCCESS_HEADLINE: 'Tiga erfolgreich entfernt',
    REMOVE_TIGA_SUCCESS_TEXT:
      'Dieses Mitglied wird dir nicht länger angezeigt.',
    REMOVE_TIGA_ERROR_HEADLINE: 'Das Mitglied konnte nicht entfernt werden',
    REMOVE_TIGA_ERROR_TEXT: 'Bitte versuche es später noch einmal.',
    LimitExceededException:
      'Maximale Versuchsanzahl ausgeschöpft. Bitte versuche es später noch einmal.',
    TODAY: 'Heute',
    YESTERDAY: 'Gestern',
    THIS_WEEK: 'Diese Woche',
    LAST_WEEK: 'Letzte Woche',
    THIS_MONTH: 'Diesen Monat',
    LAST_MONTH: 'Letzten Monat',
  },
};
