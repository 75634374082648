import React, { useEffect, useMemo, useState } from 'react';
import { Navigation } from '../../components/Navigation';
import { Headline } from '../../components/Headline';
import { Link } from '../../components/Link';
import { Button } from '../../components/Button';
import { Paragraph } from '../../components/Paragraph';
import { Card } from '../../components/Card';
import { Input } from '../../components/Input';
import { Footer } from '../../components/Footer';
import { useTranslation } from 'react-i18next';
import { routes } from '../../routes';
import {
  getStripeBillingPortalSession,
  getStripeCustomerSubscription,
  getStripePaymentMethod,
  updateStripeCustomer,
} from '../../services/paymentService';
import { useGlobalState } from '../../State';
import { updateUser } from '../../services/authService';
import { ToastMessage } from '../../components/ToastMessage';
import { ToastMessageData } from '../../components/ToastMessage/ToastMessage';
import { Content } from '../../components/Content';
import { Radio } from '../../components/Radio';
import i18n from '../../i18n';
import tryNTimes from '../../utilities/retryAsync';
import Stripe from 'stripe';
import moment from 'moment';

export const Profile: React.FC = () => {
  const { t } = useTranslation();
  const [{ user }, { setUser }] = useGlobalState();

  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [email, setEmail] = useState(user?.email);
  const [locale, setLocale] = useState(i18n.language);

  // reset unsaved local changes on unmount
  useEffect(() => {
    return () => {
      setLocale(i18n.language);
    };
  }, []);

  const toastMessageSuccess: ToastMessageData = {
    type: 'success',
    headline: t('PROFILE_SAVE_SUCCESS_HEADLINE'),
    text: t('PROFILE_SAVE_SUCCESS_TEXT'),
  };

  const toastMessageError: ToastMessageData = {
    type: 'error',
    headline: t('PROFILE_SAVE_ERROR_HEADLINE'),
    text: t('PROFILE_SAVE_ERROR_TEXT'),
  };

  const toastMessagePaymentDataError: ToastMessageData = {
    type: 'error',
    headline: t('PROFILE_PAYMENTDATA_RETRIVE_ERROR_HEADLINE'),
    text: t('PROFILE_PAYMENTDATA_RETRIVE_ERROR_TEXT'),
  };

  const [toastMessage, setToastMessage] = useState(toastMessageSuccess);

  const [showToastMessage, setShowToastMessage] = useState(false);

  const [membership, setMembership] = useState<string | undefined>();
  const [nextPayment, setNextPayment] = useState<string | undefined>();
  const [paymentMethod, setPaymentMethod] = useState('...');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let submitToastMessage: ToastMessageData;
    i18n.changeLanguage(locale);

    if (
      user &&
      user.id &&
      user.stripeId &&
      firstName &&
      lastName &&
      email &&
      locale &&
      (firstName !== user?.firstName ||
        lastName !== user?.lastName ||
        email !== user?.email ||
        locale !== user.locale)
    ) {
      console.log(locale);
      const newUser = { ...user, firstName, lastName, email, locale };

      try {
        await updateUser(newUser);
        await updateStripeCustomer(user.id, user.stripeId, user.email);

        setUser(newUser);
        submitToastMessage = toastMessageSuccess;
      } catch (error) {
        console.error(error);
        submitToastMessage = toastMessageError;
      }
    } else {
      submitToastMessage = toastMessageError;
    }
    setToastMessage(submitToastMessage);
    setShowToastMessage(true);
  };

  useEffect(() => {
    (async () => {
      if (user) {
        try {
          const subscription = await tryNTimes<Stripe.Subscription>(
            getStripeCustomerSubscription,
            [user.stripeId],
            5,
          );

          const paymentMethod = await tryNTimes<Stripe.PaymentMethod>(
            getStripePaymentMethod,
            [subscription.default_payment_method],
            5,
          );

          setPaymentMethod(
            `${t(paymentMethod.type)}${
              paymentMethod.card ? ` - ${paymentMethod.card?.brand}` : ''
            }`,
          );

          const currentPeriodEnd = moment
            .unix(subscription.current_period_end)
            .format('DD.MM.YYYY');

          setNextPayment(
            subscription.cancel_at_period_end
              ? `${t('SUBSCRIPTION_CANCELED_AT')} ${currentPeriodEnd}`
              : currentPeriodEnd,
          );
          setMembership(moment.unix(subscription.created).format('DD.MM.YYYY'));
        } catch (error) {
          console.error(error);
          setToastMessage(toastMessagePaymentDataError);
          setShowToastMessage(true);
        }
      }
    })();
  }, []);
  return (
    <>
      <Navigation />
      <Content>
        <div className="container">
          <div className="row">
            <div className="col-12 sm:col-11 lg:col-4 mb-1 sm:offset-1 lg:offset-0">
              <Link to={routes.dashboard.path}>
                <Paragraph
                  level="link"
                  color="blue"
                  text={t(`BACK_TO_DASHBOARD`)}
                />
              </Link>
            </div>
          </div>
        </div>

        <div className="container lg:row lg:flex">
          {/* Profil */}
          <div className="col-12 mb-6 no-padding sm:offset-1 sm:col-10 lg:offset-0 lg:col-8">
            <div className="lg:pr-4">
              <div className="mb-4">
                <Headline level="h1" color="blue" text={t(`TIGAPROFIL`)} />
              </div>

              <div className="mb-3">
                <Paragraph level="body" color="dark" text={t(`EDIT_PROFILE`)} />
              </div>

              <Card>
                <div className="pt-xl px-3 pb-xxl sm:px-xl lg:px-0 lg:pt-9 lg:pb-9">
                  <form
                    className="lg:flex lg:row lg:flex-wrap"
                    onSubmit={handleSubmit}
                  >
                    <div className="mb-3 lg:offset-1 lg:col-5 lg:pr-4 lg:mb-3">
                      <Input
                        color="dark"
                        type="text"
                        label={t(`FIRSTNAME`)}
                        initialValue={user?.firstName}
                        id="signup_first_name"
                        autoComplete="given-name"
                        onChange={(event) => setFirstName(event.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3 lg:col-5 lg:mb-3">
                      <Input
                        color="dark"
                        type="text"
                        label={t(`LASTNAME`)}
                        initialValue={user?.lastName}
                        id="signup_last_name"
                        autoComplete="family-name"
                        onChange={(event) => setLastName(event.target.value)}
                        required
                      />
                    </div>
                    <div className="lg:offset-1 lg:col-10">
                      <Input
                        color="dark"
                        type="email"
                        disabled={true}
                        label={t(`EMAIL`)}
                        initialValue={user?.email}
                        id="signup_email"
                        autoComplete="email"
                        onChange={(event) => setEmail(event.target.value)}
                        required
                      />
                    </div>
                    <div className="lg:offset-1 lg:col-10 mt-m">
                      <div className="mb-s">
                        <Headline
                          level="h3"
                          color="blue"
                          text={t('LANGUAGE')}
                        />
                      </div>
                      <Radio
                        initialSelection={locale}
                        name="language"
                        options={[
                          {
                            id: 'languageSelectGerman',
                            label: t('GERMAN'),
                            value: 'de',
                          },
                          {
                            id: 'languageSelectEnglish',
                            label: t('ENGLISH'),
                            value: 'en',
                          },
                        ]}
                        onSelect={(value) => {
                          setLocale(value);
                        }}
                      />
                    </div>
                    <div className="col-12 mt-5 md:col-6 lg:offset-1 lg:mt-4">
                      <Button
                        level="primary"
                        text={t(`SAVE`)}
                        type="submit"
                        disabled={useMemo(
                          () =>
                            firstName === user?.firstName &&
                            lastName === user?.lastName &&
                            email === user?.email &&
                            locale === user?.locale,
                          [user, firstName, lastName, email, locale],
                        )}
                      />
                    </div>
                  </form>
                </div>
              </Card>
            </div>
          </div>

          {/* Abonnement */}
          <div className="col-12 no-padding sm:offset-1 sm:col-10 lg:offset-0 lg:col-4">
            <div className="mb-4">
              <Headline level="h1" color="blue" text={t(`ABONNEMENT`)} />
            </div>

            <div className="flex mb-3">
              <div className="mr-1">
                <Paragraph
                  level="body"
                  color="dark"
                  text={`${t('MEMBER_SINCE')}:`}
                />
              </div>
              <Paragraph level="body" color="dark" text={membership ?? '...'} />
            </div>

            <Card>
              <div className="pt-xxl px-5 pb-xxl sm:px-8 lg:px-6">
                <div className="flex mb-3">
                  <div className="mr-1">
                    <Paragraph
                      level="body"
                      color="dark"
                      text={`${t('NEXT_PAYMENT')}:`}
                    />
                  </div>
                  <Paragraph
                    level="body"
                    color="dark"
                    text={nextPayment ?? '...'}
                  />
                </div>
                <div className="flex mb-3">
                  <div className="mr-1">
                    <Paragraph
                      level="body"
                      color="dark"
                      text={`${t('PAYMENT_METHOD')}:`}
                    />
                  </div>
                  <Paragraph level="body" color="dark" text={paymentMethod} />
                </div>
                <div className="mb-2">
                  <a
                    href="#"
                    onClick={async (event) => {
                      event.preventDefault();

                      if (user && user.stripeId) {
                        try {
                          const url = await getStripeBillingPortalSession(
                            user.stripeId,
                          );

                          window.location.href = url;
                        } catch (error) {
                          console.error(error);
                        }
                      }
                    }}
                  >
                    <Paragraph
                      level="link"
                      color="blue"
                      text={t(`EDIT_SUBSCRIPTION`)}
                    />
                  </a>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <ToastMessage
          isVisible={showToastMessage}
          type={toastMessage.type}
          headline={toastMessage.headline}
          text={toastMessage.text}
          onToggle={() => {
            setShowToastMessage(false);
          }}
          timeout={true}
        ></ToastMessage>
      </Content>
      <Footer />
    </>
  );
};
