import React from 'react';
export { Table, TableRow } from './Table';

// number refers to grid values from 1 to 12
export type TableLayout = {
  xs?: string[];
  sm?: string[];
  md?: string[];
  lg?: string[];
  xl?: string[];
};

export type Head = {
  text: string;
  tooltip: string;
};

export type TableProps = {
  children?: React.ReactNode[] | React.ReactNode;
  heads?: Head[];
  layout?: TableLayout;
};

type breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
const breakpoints: breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl'];

export const getBreakpointClasses: (
  index: number,
  layout: TableLayout,
) => string = (index, layout) =>
  breakpoints
    .map((breakpoint) => {
      const breakpointColumns = layout[breakpoint] ? layout[breakpoint] : null;

      return layout && breakpointColumns
        ? `${breakpoint}:col-${breakpointColumns[index]}`
        : null;
    })
    .flat()
    .join(' ');
