import moment from 'moment';
import { DateRange } from '../components/Datepicker/Datepicker';
import { getAccessToken } from './authService';

type BackendResponse = {
  status: 'success' | 'error';
  error?: string;
};
export interface UserEventsResponse extends BackendResponse {
  data: UserEventsData[];
}

export type UserEventsData = {
  user_id: string;
  firstname: string;
  lastname: string;
  family_count: number;
  calls: EventDataPoint[];
  appointments: EventDataPoint[];
  sales_total: number;
  call_attempt_total: number;
  call_success_total: number;
  appointments_total: number;
  appointments_planned_total: number;
  relation_id: string;
  contacts_selected: number;
  contacts_total: number;
};

export type EventDataPoint = { t: string; y: string };

export const getUserEvents: (
  dateRange: DateRange,
  userId?: string,
) => Promise<UserEventsData[]> = async (dateRange, userId) => {
  const accessToken = await getAccessToken();
  const options: {
    [key: string]: string;
  } = {};

  if (dateRange.startDate && dateRange.endDate) {
    if (userId) options.user_ids = userId;

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_BASEURL}/events?${new URLSearchParams({
        startdate: moment(dateRange.startDate).format('YYYY-MM-DD').toString(),
        enddate: moment(dateRange.endDate).format('YYYY-MM-DD').toString(),
        ...options,
      })}`,
      {
        method: 'get',
        headers: new Headers({
          Authorization: accessToken,
        }),
      },
    );

    const data: UserEventsResponse = await response.json();

    if (response.status !== 200 || data.status === 'error') {
      throw new Error(`${response.status}: ${data.error}`);
    } else {
      return data.data;
    }
  }

  throw new Error(`please provide startDate and endDate`);
};

export const inviteTiga: (
  email: string,
) => Promise<'success' | 'error'> = async (email) => {
  const accessToken = await getAccessToken();
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_BASEURL}/relation-requests`,
    {
      method: 'post',
      headers: new Headers({
        Authorization: accessToken,
      }),
      body: JSON.stringify({
        email,
      }),
    },
  );

  const data: BackendResponse = await response.json();
  if (response.status === 404 || response.status === 200) {
    return data.status;
  } else {
    throw new Error(`${response.status}: ${data.error}`);
  }
};

export const removeTigaFromFamily: (
  relationId: string,
) => Promise<'success' | 'error'> = async (relationId) => {
  const accessToken = await getAccessToken();
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_BASEURL}/relation-requests/${relationId}`,
    {
      method: 'DELETE',
      headers: new Headers({
        Authorization: accessToken,
      }),
    },
  );

  const data: BackendResponse = await response.json();
  if (response.status === 404 || response.status === 200) {
    return data.status;
  } else {
    throw new Error(`${response.status}: ${data.error}`);
  }
};
