import React, { useState } from 'react';
import { Link } from '../../components/Link';
import { Button } from '../../components/Button';
import { Headline } from '../../components/Headline';
import { Input } from '../../components/Input';
import { routes } from '../../routes';
import { Paragraph } from '../../components/Paragraph';
import { ToastMessage } from '../../components/ToastMessage';
import { Icon } from '../../components/Icon/Icon';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import { TigaContainer } from '../../components/TigaContainer';
import {
  forgotPassword,
  forgotPasswordSubmit,
} from '../../services/authService';
import { MultiStepForm } from '../../components/MultiStepForm';

export const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [resetError, setResetError] = useState<string>('');
  const [currentFormStep, setCurrentFormStep] = useState(0);
  const [showClearPassword, setShowClearPassword] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const { t } = useTranslation();

  const handleEmailSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (email) {
      try {
        await forgotPassword(email);
        setCurrentFormStep(currentFormStep + 1);
      } catch (error) {
        setResetError(error.message);
        setShowToast(true);
      }
    }
  };

  const handleConfirmSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();

    if (email && code && password) {
      try {
        await forgotPasswordSubmit(email, code, password);
        setCurrentFormStep(currentFormStep + 1);
      } catch (error) {
        setResetError(error.message);
        setShowToast(true);
      }
    }
  };

  return (
    <>
      <TigaContainer>
        <div className="my-120px md:my-0">
          <div className="w-120px md:w-240px block m-auto my-s">
            <Icon name="Logo" color="blue-primary" />
          </div>
          <div className="text-center">
            <Headline level="h2" color="blue" text={t(`PASSWORD_FORGOT`)} />
          </div>
        </div>
        <div>
          <MultiStepForm
            currentStep={currentFormStep}
            steps={[
              <form key={1} className="space-y-4" onSubmit={handleEmailSubmit}>
                {/* step 1 */}
                <div className="row mt-6">
                  <div className="col-12">
                    <Input
                      color="blue"
                      type="email"
                      label={t(`EMAIL`)}
                      id="signup_email"
                      autoComplete="email"
                      onChange={(event) => setEmail(event.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="mt-5">
                  <Button
                    level="primary"
                    text={t(`PASSWORD_RESET`)}
                    type="submit"
                  />
                </div>
              </form>,
              <form
                key={2}
                className="space-y-4"
                onSubmit={handleConfirmSubmit}
              >
                {/* step 2 */}
                <div className="row mt-6 whitespace-pre-wrap">
                  <div className="col-12">
                    <div className="mb-6">{t('RESET_CODE_EXPLANATION')}</div>
                    <Input
                      color="blue"
                      type="numbers"
                      label={t('RESET_CODE')}
                      id="resetPin"
                      autoComplete="one-time-code"
                      maxLength={6}
                      onChange={(event) => setCode(event.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="mt-4 relative">
                  <Input
                    color="blue"
                    type={showClearPassword ? 'text' : 'password'}
                    label={t('NEW_PASSWORD')}
                    id="signin_password"
                    autoComplete="new-password"
                    passwordValidation={true}
                    onChange={(event) => setPassword(event.target.value)}
                    required
                  />
                  <div className="absolute top-0.5 right-l">
                    <Button
                      tabIndex={-1}
                      level="icon"
                      type="button"
                      icon="Password"
                      iconColor="light"
                      onClick={() => setShowClearPassword(!showClearPassword)}
                    />
                  </div>
                </div>
                <div className="mt-5">
                  <Button
                    level="primary"
                    text={t(`PASSWORD_RESET`)}
                    type="submit"
                  />
                </div>
              </form>,
              <div key={3} className="row m-6 whitespace-pre-wrap">
                {/* step success */}
                <div className="col-12">{t('RESET_SUCCESS')}</div>
              </div>,
            ]}
          />
          <div className="row my-xs">
            <div className="col-12 md:col-6 text-center md:text-left my-xs md:m-0">
              <Link to={routes.signin.path}>
                <Paragraph
                  level="link"
                  color="blue"
                  text={t(`ALREADY_SIGNED_IN`)}
                />
              </Link>
            </div>
            <div className="col-12 md:col-6 text-center md:text-right my-xs md:m-0">
              <Link to={routes.signup.path}>
                <Paragraph level="link" color="blue" text={t(`SIGNUP_CTA`)} />
              </Link>
            </div>
          </div>
        </div>
      </TigaContainer>
      <ToastMessage
        isVisible={showToast}
        type="error"
        headline={t('PASSWORD_RESET_ERROR_HEADLINE')}
        text={t(resetError)}
        onToggle={(state: boolean) => {
          setShowToast(state);
        }}
        timeout={true}
      ></ToastMessage>
    </>
  );
};
