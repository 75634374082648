import React, { useCallback } from 'react';

export type ParagraphLevels = 'body' | 'button' | 'small' | 'caption';
export type ParagraphProps = {
  level: 'body' | 'button' | 'small' | 'caption' | 'link' | 'body-link';
  color: 'blue' | 'dark' | 'light' | 'white' | 'error';
  text: string;
};

export const Paragraph: React.FC<ParagraphProps> = ({
  level,
  color,
  text,
}: ParagraphProps) => {
  const renderParagraph = useCallback(() => {
    const styles = ['font-sans'];
    switch (color) {
      case 'blue':
        styles.push('text-blue-interactive');
        break;
      case 'dark':
        styles.push('text-black');
        break;
      case 'light':
        styles.push('text-grey-200');
        break;
      case 'white':
        styles.push('text-white');
        break;
      case 'error':
        styles.push('text-error');
        break;
    }

    switch (level) {
      case 'body':
        styles.push('text-base', 'leading-5');
        return <p className={styles.join(' ')}>{text}</p>;
      case 'button':
        styles.push('text-md', 'font-bold', 'leading-5', 'uppercase');
        return <span className={styles.join(' ')}>{text}</span>;
      case 'small':
        styles.push('text-xxxs', 'leading-1');
        return <p className={styles.join(' ')}>{text}</p>;
      case 'caption':
        styles.push('text-xxs font-thin tracking-tight');
        return <p className={styles.join(' ')}>{text}</p>;
      case 'link':
        styles.push(
          'text-blue-interactive underline uppercase text-xxs cursor-pointer hover:text-orange transition-colors',
        );
        return <span className={styles.join(' ')}>{text}</span>;
      case 'body-link':
        styles.push(
          'text-base',
          'cursor-pointer',
          'leading-3',
          'hover:underline transition-colors',
        );
        return <p className={styles.join(' ')}>{text}</p>;
    }
  }, [level, color, text]);

  return <>{renderParagraph()}</>;
};
