import React, { useCallback, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Button } from '../Button';
import { useInterval } from '../../utilities/setInterval';
import { EventDataPoint } from '../../services/dataService';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '../Paragraph';
import moment from 'moment';

export type ChartProps = {
  datasets: {
    label: string;
    color: string;
    hoverColor: string;
    data: EventDataPoint[];
  }[];
};

export const Chart: React.FC<ChartProps> = ({ datasets }: ChartProps) => {
  const barConfig = {
    datasets: datasets.map((dataset) => ({
      label: dataset.label,
      backgroundColor: dataset.color,
      hoverBorderColor: dataset.hoverColor,
      data: dataset.data,
      barPercentage: 1,
      categoryPercentage: dataset.data.length < 20 ? 0.2 : 1,
      maxBarThickness: 24,
    })),
  };

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          ticks: {
            min: moment(datasets[0].data[0].t).subtract(1, 'd').toISOString(),
            max: moment(datasets[0].data[datasets[0].data.length - 1].t)
              .add(1, 'd')
              .toISOString(),
          },
          time: {
            bounds: 'ticks',
            unit: 'day',
            stepSize: Math.floor(datasets[0].data.length / 20) + 1,
            displayFormats: {
              quarter: 'MMM YYYY',
            },
          },
        },
      ],
      yAxes: [
        {
          type: 'linear',
          ticks: {
            stepSize: 100,
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const { t } = useTranslation();
  const chartContainerRef = useRef<null | HTMLDivElement>(null);
  const chartRef = useRef<null | Bar>(null);
  const [isScrollRightDown, setScrollRightDown] = useState(false);
  const [isScrollLeftDown, setScrollLeftDown] = useState(false);
  const [hideNavigationArrows, setHideNavigationArrows] = useState({
    start: true,
    end: false,
  });

  const handleArrowDisplay = useCallback(() => {
    if (chartContainerRef.current && chartRef.current) {
      const overflowWidth =
        (chartRef.current.props.width ?? 1100) -
        chartContainerRef.current.clientWidth;

      const updateHideNavigationArrows = { ...hideNavigationArrows };
      updateHideNavigationArrows.start =
        chartContainerRef.current.scrollLeft === 0;
      updateHideNavigationArrows.end =
        chartContainerRef.current.scrollLeft >= overflowWidth;

      setHideNavigationArrows(updateHideNavigationArrows);
    }
  }, [chartContainerRef, chartRef]);

  const scrollChart = useCallback(
    (direction: 'left' | 'right') => {
      if (chartContainerRef.current && chartRef.current) {
        const overflowWidth =
          (chartRef.current.props.width ?? 1100) -
          chartContainerRef.current.clientWidth;

        const scrollValue =
          chartContainerRef.current.scrollLeft +
          (direction === 'left' ? -100 : 100);

        chartContainerRef.current.scroll(
          scrollValue > overflowWidth
            ? overflowWidth
            : scrollValue < 0
            ? 0
            : scrollValue,
          0,
        );

        handleArrowDisplay();
      }
    },
    [chartContainerRef, chartRef],
  );

  useInterval(() => scrollChart('right'), isScrollRightDown ? 100 : null);
  useInterval(() => scrollChart('left'), isScrollLeftDown ? 100 : null);

  return (
    <div className="relative mx-2">
      <div className="row flex justify-center my-s space-y-xs md:space-y-0">
        <div className="flex col-12 md:col-2 flex items-center md:justify-center space-x-xs">
          <div className="w-s h-s block rounded-full bg-blue-primary"></div>
          <Paragraph color="light" level="small" text={t('CALLS')} />
        </div>
        <div className="flex col-12 md:col-2 items-center md:justify-center space-x-xs">
          <div className="w-s h-s block rounded-full bg-orange"></div>
          <Paragraph color="light" level="small" text={t('APPOINTMENTS')} />
        </div>
      </div>
      <div className="relative">
        <div
          ref={chartContainerRef}
          className="overflow-scroll"
          onScroll={() => handleArrowDisplay()}
          style={{ scrollBehavior: 'smooth' }}
        >
          <Bar
            ref={chartRef}
            data={barConfig}
            width={1100}
            height={250}
            options={options}
          />
        </div>
        <div
          className={`absolute right-0 top-0 h-full bg-white flex items-center justify-center w-5 transition-all duration-200 ${
            hideNavigationArrows.end ? 'opacity-0' : 'lg:hidden'
          }`}
        >
          <Button
            level="icon"
            icon={'ArrowRight'}
            iconColor="dark"
            iconWidth={10}
            onPointerDown={() => setScrollRightDown(true)}
            onPointerUp={() => setScrollRightDown(false)}
          />
        </div>
        <div
          className={`absolute left-0 top-0 h-full bg-white flex items-center justify-center w-5 transition-all duration-200 ${
            hideNavigationArrows.start ? 'opacity-0' : 'lg:hidden'
          }`}
        >
          <Button
            level="icon"
            icon={'ArrowLeft'}
            iconColor="dark"
            iconWidth={10}
            onPointerDown={() => setScrollLeftDown(true)}
            onPointerUp={() => setScrollLeftDown(false)}
          />
        </div>
      </div>
    </div>
  );
};
