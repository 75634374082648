import React, { FC, useMemo } from 'react';

import { ReactComponent as Add } from '../../icons/add.svg';
import { ReactComponent as ArrowSmall } from '../../icons/arrow_small.svg';
import { ReactComponent as ArrowDown } from '../../icons/arrow_down.svg';
import { ReactComponent as ArrowUp } from '../../icons/arrow_up.svg';
import { ReactComponent as ArrowRight } from '../../icons/arrow_right.svg';
import { ReactComponent as ArrowLeft } from '../../icons/arrow_left.svg';
import { ReactComponent as BackToTop } from '../../icons/backToTop.svg';
import { ReactComponent as Calendar } from '../../icons/calendar.svg';
import { ReactComponent as CircleArrowLeft } from '../../icons/circle_arrow_left.svg';
import { ReactComponent as Close } from '../../icons/close.svg';
import { ReactComponent as CloseSmall } from '../../icons/close_small.svg';
import { ReactComponent as Delete } from '../../icons/delete.svg';
import { ReactComponent as Error } from '../../icons/error.svg';
import { ReactComponent as Facebook } from '../../icons/facebook.svg';
import { ReactComponent as Instagram } from '../../icons/instagram.svg';
import { ReactComponent as Linkedin } from '../../icons/linkedin.svg';
import { ReactComponent as Logo } from '../../icons/logo.svg';
import { ReactComponent as MenuHome } from '../../icons/menu_home.svg';
import { ReactComponent as MenuIndicator } from '../../icons/menu_indicator.svg';
import { ReactComponent as MenuContact } from '../../icons/menu_kontakt.svg';
import { ReactComponent as MenuMore } from '../../icons/menu_mehr.svg';
import { ReactComponent as Menu } from '../../icons/menu.svg';
import { ReactComponent as MessageArrowRight } from '../../icons/message_arrow_right.svg';
import { ReactComponent as Password } from '../../icons/password.svg';
import { ReactComponent as PasswordInvisible } from '../../icons/password_invisible.svg';
import { ReactComponent as Search } from '../../icons/search.svg';
import { ReactComponent as Success } from '../../icons/success.svg';
import { ReactComponent as Sort } from '../../icons/sort.svg';
import { ReactComponent as Tooltip } from '../../icons/tooltip.svg';
import { ReactComponent as Up } from '../../icons/up.svg';

export const icons: { [key: string]: FC } = {
  Add,
  ArrowSmall,
  ArrowDown,
  ArrowUp,
  ArrowRight,
  ArrowLeft,
  BackToTop,
  Calendar,
  CircleArrowLeft,
  Close,
  CloseSmall,
  Delete,
  Error,
  Facebook,
  Instagram,
  Linkedin,
  Logo,
  MenuHome,
  MenuIndicator,
  MenuContact,
  MenuMore,
  Menu,
  MessageArrowRight,
  Password,
  PasswordInvisible,
  Search,
  Success,
  Sort,
  Tooltip,
  Up,
};

export const iconNames = [
  'Add',
  'ArrowSmall',
  'ArrowDown',
  'ArrowDown',
  'ArrowUp',
  'ArrowRight',
  'ArrowLeft',
  'BackToTop',
  'Calendar',
  'CircleArrowLeft',
  'Close',
  'CloseSmall',
  'Delete',
  'Error',
  'Delete',
  'Facebook',
  'Instagram',
  'Linkedin',
  'Logo',
  'MenuHome',
  'MenuIndicator',
  'MenuContact',
  'MenuMore',
  'Menu',
  'MessageArrowRight',
  'Password',
  'PasswordInvisible',
  'Search',
  'Success',
  'Sort',
  'Tooltip',
  'Up',
] as const;

export type iconColors =
  | 'blue'
  | 'blue-primary'
  | 'dark'
  | 'light'
  | 'white'
  | 'success'
  | 'error';

export type IconProps = {
  name: typeof iconNames[number];
  title?: string;
  width?: number;
  height?: number;
  color: iconColors;
  hover?: boolean;
};

export const Icon: FC<IconProps> = ({
  name,
  title,
  width,
  height,
  color,
  hover,
}: IconProps) => {
  const IconByName: FC<{ className: string; title?: string }> = useMemo(() => {
    return icons[name];
  }, [name]);

  let colorClass;
  switch (color) {
    case 'white':
      colorClass = `text-white ${hover ? 'hover:text-grey-200' : ''}`;
      break;
    case 'dark':
      colorClass = `text-grey-400 ${hover ? 'hover:text-black' : ''}`; // TODO: hover color code tbd
      break;
    case 'light':
      colorClass = `text-grey-200 ${hover ? 'hover:text-grey-400' : ''}`;
      break;
    case 'blue':
      colorClass = `text-blue-interactive ${
        hover ? 'hover:text-blue-secondary' : ''
      }`;
      break;
    case 'blue-primary':
      colorClass = 'text-blue-primary';
      break;
    case 'success':
      colorClass = `text-success ${hover ? 'hover:text-success' : ''}`; // TODO: hover color code tbd
      break;
    case 'error':
      colorClass = `text-error ${hover ? 'hover:text-error' : ''}`; // TODO: hover color code tbd
      break;

    default:
      break;
  }

  return typeof IconByName !== 'undefined' ? (
    <span
      className="block m-auto"
      style={{
        width: width ? `${width}px` : 'auto',
        height: height ? `${height}px` : 'auto',
      }}
    >
      <IconByName
        className={`max-h-full w-auto fill-current stroke-current block m-auto ${colorClass}`}
        title={title}
      />
    </span>
  ) : null;
};
