import React, { useCallback, useMemo, useState } from 'react';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { useTranslation } from 'react-i18next';
import { Headline } from '../Headline';
import { Paragraph } from '../Paragraph';
import { Link } from '../Link';
import { PopupContainer } from '../PopupContainer';
import { iconNames } from '../Icon/Icon';
import { routes } from '../../routes';
import { useGlobalState } from '../../State';
import { Input } from '../Input';
import { MenuItem } from './MenuItem';
import { inviteTiga } from '../../services/dataService';
import {
  toastMessageInviteError,
  toastMessageInviteNotFoundError,
  toastMessageInviteSuccess,
} from './NavigationToastMessages';
import { toastMessageDataFetchError } from '../../features/Dashboard/DashboardToastMessage';
import { ToastMessage } from '../ToastMessage';
import { ToastMessageData } from '../ToastMessage/ToastMessage';

export type NavigationLink = {
  text: string;
  icon?: typeof iconNames[number];
  action?: string;
  href?: string;
  condition?: 'authenticated';
};

export const Navigation: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const [{ user }] = useGlobalState();
  const [showPopup, setShowPopup] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');

  const [toastMessage, setToastMessage] = useState(toastMessageDataFetchError);
  const [showToastMessage, setShowToastMessage] = useState(false);

  const menuLinks = useMemo(() => {
    const links: NavigationLink[] = [
      {
        text: t('INVITE_TIGA'),
        icon: 'Add',
        action: 'add',
        condition: 'authenticated',
      },
      {
        text: t('PROFIL'),
        href: routes.profile.path,
        condition: 'authenticated',
      },
      {
        text: t('LOGOUT'),
        action: 'logout',
      },
    ];
    return links.filter((link) =>
      link.condition === 'authenticated' ? user && user.stripeId : true,
    );
  }, [t]);

  const handleInviteSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      let submitToastMessage: ToastMessageData;

      if (inviteEmail && inviteEmail !== user?.email) {
        try {
          const response = await inviteTiga(inviteEmail);

          if (response === 'success') {
            submitToastMessage = toastMessageInviteSuccess;
          } else {
            submitToastMessage = toastMessageInviteNotFoundError;
          }
        } catch (error) {
          console.error(error);
          submitToastMessage = toastMessageInviteError;
        }
      } else {
        submitToastMessage = toastMessageInviteError;
      }
      setInviteEmail('');
      setShowPopup(false);
      setToastMessage(submitToastMessage);
      setShowToastMessage(true);
    },
    [
      inviteEmail,
      setShowPopup,
      setToastMessage,
      setShowToastMessage,
      toastMessageInviteSuccess,
      toastMessageInviteError,
    ],
  );

  return (
    <>
      <div className="relative bg-blue-primary mb-m md:mb-xxxl">
        {/* Invite Tiga PopUp */}
        <PopupContainer open={showPopup} onBlur={() => setShowPopup(false)}>
          <div className="p-6 w-full">
            <Headline
              level="h3"
              color="blue"
              text={t('INVITE_TIGA_POPUP_HEAD')}
            />
            <div className="my-4">
              <Paragraph
                level="body"
                color="dark"
                text={t('INVITE_TIGA_POPUP_BODY')}
              />
            </div>
            <form onSubmit={handleInviteSubmit}>
              <Input
                color="dark"
                type="email"
                label={t('EMAIL')}
                id="popupInviteEmail"
                autoComplete="email"
                onChange={(event) => {
                  setInviteEmail(event.target.value);
                }}
                required
              />
              <div className="flex justify-end">
                <div className="flex mt-4">
                  <div className="mr-4">
                    <Button
                      type="reset"
                      level="secondary"
                      color="dark"
                      text={t('CANCEL')}
                      onClick={() => {
                        setShowPopup(false);
                        setInviteEmail('');
                      }}
                    />
                  </div>
                  <Button
                    level="primary"
                    color="blue"
                    text={t('INVITE')}
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </div>
        </PopupContainer>

        <div className="relative container flex items-center h-xxxl py-0.5 justify-between z-40 bg-blue-primary border-solid border-b-2 border-white md:border-none">
          <div className="w-120px ">
            <Link to={routes.dashboard.path}>
              <Icon name="Logo" color="white" />
            </Link>
          </div>
          <div className="md:hidden">
            {isOpen ? (
              <Button
                aria-label={t('MENU_TOGGLE_CLOSE')}
                level="icon"
                icon="Close"
                iconColor="white"
                onClick={() => setOpen(false)}
              />
            ) : (
              <Button
                aria-label={t('MENU_TOGGLE_OPEN')}
                level="icon"
                icon="Menu"
                iconColor="white"
                onClick={() => setOpen(true)}
              />
            )}
          </div>

          {/* desktop menu */}
          <nav className="hidden md:flex space-x-xxxl items-center">
            {menuLinks.map(({ text, icon, action, href }, index) => (
              <MenuItem
                key={index}
                action={action}
                href={href}
                onPopupChange={setShowPopup}
              >
                <span className="flex items-center space-x-s">
                  {icon ? <Icon name={icon} color="white" width={24} /> : null}
                  <Paragraph level="body-link" color="white" text={text} />
                </span>
              </MenuItem>
            ))}
          </nav>
        </div>

        {/* mobile menu */}
        <nav
          className={`md:hidden absolute left-0 top-xxxl bg-blue-primary w-full transform duration-300 z-30 ${
            isOpen ? 'translate-y-0' : '-translate-y-full'
          }`}
        >
          <ul className="flex text-white container flex-col text-center">
            {menuLinks.map(({ text, action, href }, index) => (
              <MenuItem
                key={index}
                action={action}
                href={href}
                onPopupChange={setShowPopup}
              >
                <li className="py-4 mx-4">
                  <Headline level="h3" color="white" text={text} />
                </li>
              </MenuItem>
            ))}
          </ul>
        </nav>
      </div>

      <ToastMessage
        isVisible={showToastMessage}
        type={t(toastMessage.type)}
        headline={t(toastMessage.headline)}
        text={t(toastMessage.text)}
        onToggle={() => {
          setShowToastMessage(false);
        }}
        timeout={true}
      />
    </>
  );
};
