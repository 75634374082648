import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../components/Card';
import { Headline } from '../../components/Headline';
import { Icon } from '../../components/Icon';
import { Paragraph } from '../../components/Paragraph';
import { PaymentPlan } from '../../services/authService';

type PaymentOptionSelectProps = {
  initialValue?: PaymentPlan;
  onSelect?: (value: null | PaymentPlan) => void;
};
export const PaymentOptionSelect: React.FC<PaymentOptionSelectProps> = ({
  initialValue,
  onSelect,
}: PaymentOptionSelectProps) => {
  const { t } = useTranslation();

  const [selection, setSelection] = useState<PaymentPlan | null>(
    initialValue ?? null,
  );

  const options: {
    headline: string;
    caption: string;
    price: string;
    interval: string;
    value: PaymentPlan;
  }[] = [
    {
      headline: t('PAYMENT_NAME_MONTHLY'),
      caption: t('PAYMENT_CAPTION_MONTHLY'),
      price: t('PAYMENT_PRICE_MONTHLY'),
      interval: t('PAYMENT_INTERVAL_MONTHLY'),
      value: 'monthly',
    },
    {
      headline: t('PAYMENT_NAME_HALFYEARLY'),
      caption: t('PAYMENT_CAPTION_HALFYEARLY'),
      price: t('PAYMENT_PRICE_HALFYEARLY'),
      interval: t('PAYMENT_INTERVAL_HALFYEARLY'),
      value: 'half-yearly',
    },
    {
      headline: t('PAYMENT_NAME_YEARLY'),
      caption: t('PAYMENT_CAPTION_YEARLY'),
      price: t('PAYMENT_PRICE_YEARLY'),
      interval: t('PAYMENT_INTERVAL_YEARLY'),
      value: 'yearly',
    },
  ];

  return (
    <div className="mt-m row text-center space-y-s md:space-y-0">
      {options.map((option, index) => {
        const isSelected = option.value === selection;
        return (
          <div className="col-12 md:col-4 cursor-pointer" key={index}>
            <Card
              clickable={!isSelected}
              value={isSelected}
              onClick={() => {
                setSelection(option.value);
                if (onSelect) onSelect(option.value);
              }}
            >
              <div className="p-m md:py-xl md:px-l">
                <Headline
                  level="h2"
                  text={option.headline}
                  color={isSelected ? 'white' : 'blue'}
                />
                <div className="m-auto w-6 md:w-xxl my-s md:my-m">
                  <Icon
                    name="ArrowDown"
                    color={isSelected ? 'white' : 'dark'}
                  />
                </div>
                <div className="inline-flex items-baseline space-x-xs mb-s md:mb-l">
                  <Headline
                    level="h2"
                    text={option.price}
                    color={isSelected ? 'white' : 'blue'}
                  />
                  <Paragraph
                    level="body"
                    text={`/ ${option.interval}`}
                    color={isSelected ? 'white' : 'blue'}
                  />
                </div>
                <Paragraph
                  level="body"
                  color={isSelected ? 'white' : 'dark'}
                  text={option.caption}
                />
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
};
