import { Dashboard } from './features/Dashboard';
import { Signin } from './features/Authentication/Signin';
import { Signup } from './features/Authentication/Signup';
import { ResetPassword } from './features/Authentication/ResetPassword';
import { PaymentOptions } from './features/Authentication/PaymentOptions';
import { Profile } from './features/Profile';
import { PaymentComplete } from './features/Authentication/PaymentComplete';

export const routes = {
  dashboard: {
    path: '/',
    component: Dashboard,
  },
  signin: {
    path: '/signin',
    component: Signin,
  },
  signup: {
    path: '/signup',
    component: Signup,
  },
  paymentOptions: {
    path: '/payment',
    component: PaymentOptions,
  },
  resetPassword: {
    path: '/reset',
    component: ResetPassword,
  },
  profile: {
    path: '/profile',
    component: Profile,
  },
  paymentComplete: {
    path: '/checkout/:session',
    component: PaymentComplete,
  },
};
