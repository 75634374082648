import { Auth } from '@aws-amplify/auth';

Auth.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID,
  },
});

type CognitoUser = {
  username: string;
  attributes: RawUser;
};

// user object as returned from amplify
type RawUser = {
  email: string;
  password: string;
  isVerified?: boolean;
  'custom:firstname': string;
  'custom:lastname': string;
  'custom:staySignedIn': string;
  'custom:stripeId': string;
  'custom:paymentPlan': PaymentPlan;
  'custom:termsConsent': string;
  'custom:marketingConsent': string;
  'custom:locale': string;
  'custom:hasCompletedPayment': string;
  'custom:contactsTotal': string;
  'custom:contactsSelected': string;
};

const transformCognitoUser = (
  cognitoUser: CognitoUser,
  accessToken: string,
) => {
  const user: User = {
    id: cognitoUser.username,
    email: cognitoUser.attributes.email,
    password: cognitoUser.attributes.password,
    firstName: cognitoUser.attributes['custom:firstname'],
    lastName: cognitoUser.attributes['custom:lastname'],
    stripeId: cognitoUser.attributes['custom:stripeId'],
    paymentPlan: cognitoUser.attributes['custom:paymentPlan'],
    termsConsent: cognitoUser.attributes['custom:termsConsent'] === 'true',
    marketingConsent:
      cognitoUser.attributes['custom:marketingConsent'] === 'true',
    staySignedIn: cognitoUser.attributes['custom:staySignedIn'] === 'true',
    hasCompletedPayment:
      cognitoUser.attributes['custom:hasCompletedPayment'] === 'true',
    isVerified: cognitoUser.attributes.isVerified,
    locale: cognitoUser.attributes['custom:locale'],
    accessToken: accessToken,
    contactsSelected: parseInt(
      cognitoUser.attributes['custom:contactsSelected'] ?? 0,
    ),
    contactsTotal: parseInt(
      cognitoUser.attributes['custom:contactsTotal'] ?? 0,
    ),
  };

  return user;
};

// internal user object
export type PaymentPlan = 'monthly' | 'half-yearly' | 'yearly';
export type User = {
  id: string;
  hasCompletedPayment: boolean;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  staySignedIn?: boolean;
  isVerified?: boolean;
  stripeId?: string;
  paymentPlan?: PaymentPlan;
  termsConsent?: boolean;
  marketingConsent?: boolean;
  accessToken?: string;
  locale?: string | null;
  contactsSelected?: number;
  contactsTotal?: number;
};

export const signup: (user: User) => Promise<void> = async ({
  firstName,
  lastName,
  email,
  password,
  locale,
  contactsSelected,
  contactsTotal,
}) => {
  try {
    // Custom Attributes can only be defined as a string
    // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html#user-pool-settings-custom-attributes
    await Auth.signUp({
      username: email.toLowerCase(),
      password,
      attributes: {
        'custom:firstname': firstName,
        'custom:lastname': lastName,
        'custom:contactsSelected': `${contactsSelected}`,
        'custom:contactsTotal': `${contactsTotal}`,
        'custom:locale': locale ? locale.split('-')[0] : 'en',
      },
    });

    return;
  } catch (error) {
    console.error(error);
    throw new Error(error.name);
  }
};

export const getCurrentUser: () => Promise<User> = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const accessToken = await getAccessToken();
  return transformCognitoUser(cognitoUser, accessToken);
};

export const confirmSignUp: (
  email: string,
  code: string,
) => Promise<void> = async (email, code) => {
  try {
    await Auth.confirmSignUp(email.toLowerCase(), code);

    return;
  } catch (error) {
    console.error(error);
    throw new Error(error.name);
  }
};

export const resendConfirmSignUpCode: (email: string) => void = async (
  email,
) => {
  try {
    await Auth.resendSignUp(email);
  } catch (error) {
    console.error(error);
    throw new Error(error.name);
  }
};

export const getAccessToken: () => Promise<string> = async () => {
  const session = await Auth.currentSession();
  return session.getAccessToken().getJwtToken();
};

export const signin: (user: {
  email: string;
  password: string;
  staySignedIn?: boolean;
}) => Promise<User> = async ({ email, password }) => {
  try {
    const cognitoUser: CognitoUser = await Auth.signIn(
      email.toLowerCase(),
      password,
    );
    const accessToken = await getAccessToken();
    const user = transformCognitoUser(cognitoUser, accessToken);

    return user;
  } catch (error) {
    console.error(error);
    throw new Error(error.name);
  }
};

export const updateUser: (user: User) => Promise<void> = async (user) => {
  const currentUser = await Auth.currentAuthenticatedUser();
  try {
    await Auth.updateUserAttributes(currentUser, {
      'custom:firstname': user.firstName,
      'custom:lastname': user.lastName,
      'custom:stripeId': user.stripeId,
      'custom:staySignedIn': `${user.staySignedIn}`,
      'custom:locale': `${user.locale ? user.locale.split('-')[0] : 'en'}`,
      'custom:hasCompletedPayment': `${user.hasCompletedPayment}`,
      'custom:contactsSelected': `${user.contactsSelected}`,
      'custom:contactsTotal': `${user.contactsTotal}`,
    });
    return;
  } catch (error) {
    console.error(error);
    throw new Error(error.name);
  }
};

export const signout: () => void = async () => {
  try {
    Auth.signOut();
    return;
  } catch (error) {
    console.error(error);
    throw new Error(error.name);
  }
};

export const forgotPassword: (email: string) => void = async (email) => {
  try {
    await Auth.forgotPassword(email.toLowerCase());
    return;
  } catch (error) {
    console.error(error);
    throw new Error(error.name);
  }
};

export const forgotPasswordSubmit: (
  email: string,
  code: string,
  newPassword: string,
) => Promise<void> = async (email, code, newPassword) => {
  try {
    await Auth.forgotPasswordSubmit(email.toLowerCase(), code, newPassword);
    return;
  } catch (error) {
    console.error();
    throw new Error(`PasswordReset${error.name}`);
  }
};

export const isUserSignedIn: () => Promise<boolean> = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (error) {
    return false;
  }
};
