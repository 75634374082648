const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  white: '#FFFFFF',
  black: '#111111',
  inherit: 'inherit',
  error: '#FF7900',
  success: '#A1EAEE',
  'blue-interactive': '#026AD1',
  'blue-primary': '#1635B7',
  'blue-secondary': '#3587C4',
  orange: '#FF7900',
  grey: {
    100: '#d2d2d2',
    200: '#b2b2b2',
    400: '#494949',
  },
};

const spacings = {
  xxs: 4,
  xs: 8,
  s: 16,
  m: 24,
  l: 32,
  xl: 48,
  xxl: 56,
  xxxl: 80,
};

const breakpoints = {
  xs: 320,
  sm: 560,
  md: 768,
  lg: 1160,
  xl: 1440,
};

module.exports = {
  colors,
  spacings,
  breakpoints,
};
