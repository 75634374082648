import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Stripe from 'stripe';
import { Headline } from '../../components/Headline';
import { Paragraph } from '../../components/Paragraph';
import { routes } from '../../routes';
import { updateUser } from '../../services/authService';
import {
  addCustomerInvoiceData,
  getStripeCustomerSubscription,
} from '../../services/paymentService';
import { useGlobalState } from '../../State';
import tryNTimes from '../../utilities/retryAsync';

export const PaymentComplete: React.FC = () => {
  const [{ user }, { setUser }] = useGlobalState();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (
        user &&
        user.stripeId &&
        user.paymentPlan &&
        !user.hasCompletedPayment
      ) {
        try {
          await Promise.all([
            tryNTimes<Stripe.Subscription>(
              getStripeCustomerSubscription,
              [user.stripeId],
              5,
              {
                key: 'status',
                value: 'trialing',
              },
            ),
            addCustomerInvoiceData(user.stripeId),
          ]);

          const newUser = { ...user, hasCompletedPayment: true };
          addCustomerInvoiceData(user.stripeId);
          await updateUser(newUser);
          setUser(newUser);
        } catch (error) {
          console.error(error);
          history.push(routes.paymentOptions.path);
        }
      } else {
        history.push(routes.dashboard.path);
      }
    })();
  }, [user]);

  return (
    <div className="flex flex-col items-center justify-center w-full space-x-3 space-y-3 min-h-full-screen">
      <Headline level="h1" color="blue" text={t('PAYMENT_CONFIRM_HEADLINE')} />
      <Paragraph level="body" color="dark" text={t('PAYMENT_CONFIRM_TEXT')} />
    </div>
  );
};
