import React, { useCallback, useMemo } from 'react';
import {
  DateRange as MobileDateRangePicker,
  DateRangePicker,
} from 'react-date-range';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';
import { PopupContainer } from '../PopupContainer';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { de, enGB } from 'date-fns/locale';
import { breakpoints, colors } from '../../variables';
import { useWindowSize } from '../../utilities/useWindowSize';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../State';

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
} from 'date-fns';

export type DateRange = {
  startDate: Date | undefined;
  endDate: Date | undefined;
  key?: string;
};
type DatepickerProps = {
  initialStartDate?: Date;
  initialEndDate?: Date;
  onSelectionChange?: (dateRange: DateRange) => void;
};

export const Datepicker: React.FunctionComponent<DatepickerProps> = ({
  initialStartDate,
  initialEndDate,
  onSelectionChange,
}: DatepickerProps) => {
  const [open, setOpen] = React.useState(false);
  const [, setHasSelectedStartAndEndDate] = React.useState(false);
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: initialStartDate,
    endDate: initialEndDate,
    key: 'selection',
  });

  const toggle = useCallback(() => setOpen(!open), [open]);
  const [windowWidth] = useWindowSize();
  const { t } = useTranslation();
  const [{ user }] = useGlobalState();

  const defineds = useMemo(
    () => ({
      startOfWeek: startOfWeek(new Date()),
      endOfWeek: endOfWeek(new Date()),
      startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
      endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
      startOfToday: startOfDay(new Date()),
      endOfToday: endOfDay(new Date()),
      startOfYesterday: startOfDay(addDays(new Date(), -1)),
      endOfYesterday: endOfDay(addDays(new Date(), -1)),
      startOfMonth: startOfMonth(new Date()),
      endOfMonth: endOfMonth(new Date()),
      startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
      endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    }),
    [],
  );

  return (
    <>
      <div className="inline-block w-auto bg-white shadow-m  w-full md:w-auto">
        <div
          className="flex px-xs items-center cursor-pointer"
          onClick={toggle}
        >
          <Icon name="Calendar" color="light" width={26} height={22} />
          <input
            className="w-full md:w-xxxl text-center bg-transparent focus:outline-none my-3 mx-xs cursor-pointer"
            disabled={true}
            type="text"
            value={dateRange.startDate?.toLocaleDateString('de-DE', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            })}
          ></input>
          <Paragraph color="dark" level="body" text="-" />
          <input
            className="w-full md:w-xxxl text-center  bg-transparent focus:outline-none my-3 mx-xs cursor-pointer"
            disabled={true}
            type="text"
            value={dateRange.endDate?.toLocaleDateString('de-DE', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            })}
          ></input>
        </div>
      </div>
      <div className="fixed w-full left-s right-s flex justify-center z-50">
        <PopupContainer open={open} onBlur={toggle}>
          <div className="hidden sm:block">
            <DateRangePicker
              minDate={new Date(2020, 0, 1)}
              maxDate={new Date()}
              locale={user && user.locale === 'de' ? de : enGB}
              ranges={[dateRange]}
              rangeColors={[colors['blue-interactive']]}
              onChange={(ranges) => {
                setDateRange((ranges as { selection: DateRange }).selection);
              }}
              dateDisplayFormat="dd.MM.yyyy"
              showSelectionPreview={true}
              months={2}
              direction={
                windowWidth < breakpoints.lg ? 'vertical' : 'horizontal'
              }
              renderStaticRangeLabel={(a) => <p>{a}</p>}
              inputRanges={[]}
              staticRanges={[
                {
                  isSelected: () => false,
                  label: t('TODAY'),
                  range: () => ({
                    startDate: defineds.startOfToday,
                    endDate: defineds.endOfToday,
                  }),
                },
                {
                  isSelected: () => false,
                  label: t('YESTERDAY'),
                  range: () => ({
                    startDate: defineds.startOfYesterday,
                    endDate: defineds.endOfYesterday,
                  }),
                },

                {
                  isSelected: () => false,
                  label: t('THIS_WEEK'),
                  range: () => ({
                    startDate: defineds.startOfWeek,
                    endDate: defineds.endOfWeek,
                  }),
                },
                {
                  isSelected: () => false,
                  label: t('LAST_WEEK'),
                  range: () => ({
                    startDate: defineds.startOfLastWeek,
                    endDate: defineds.endOfLastWeek,
                  }),
                },
                {
                  isSelected: () => false,
                  label: t('THIS_MONTH'),
                  range: () => ({
                    startDate: defineds.startOfMonth,
                    endDate: defineds.endOfMonth,
                  }),
                },
                {
                  isSelected: () => false,
                  label: t('LAST_MONTH'),
                  range: () => ({
                    startDate: defineds.startOfLastMonth,
                    endDate: defineds.endOfLastMonth,
                  }),
                },
              ]}
            />
          </div>
          <div className="block sm:hidden">
            <MobileDateRangePicker
              minDate={new Date(2020, 0, 1)}
              maxDate={new Date()}
              locale={user && user.locale === 'de' ? de : enGB}
              moveRangeOnFirstSelection={false}
              ranges={[dateRange]}
              onRangeFocusChange={(focusedRange) => {
                setHasSelectedStartAndEndDate(
                  focusedRange.filter((range) => range === 1).length === 0,
                );
              }}
              dateDisplayFormat="dd.MM.yyyy"
              showSelectionPreview={true}
              rangeColors={[colors['blue-interactive']]}
              onChange={(ranges) => {
                setDateRange((ranges as { selection: DateRange }).selection);
              }}
            />
          </div>
          <div className="row flex justify-end p-s">
            <div className="md:col-4 col-6">
              <Button
                level="secondary"
                color="blue"
                text={t('CANCEL')}
                onClick={toggle}
              />
            </div>
            <div className="md:col-4 col-6">
              <Button
                level="primary"
                color="blue"
                text={t('APPLY')}
                disabled={!(dateRange.startDate !== dateRange.endDate)}
                onClick={() => {
                  if (
                    onSelectionChange &&
                    dateRange.startDate &&
                    dateRange.endDate
                  ) {
                    onSelectionChange(dateRange);
                    toggle();
                  }
                }}
              />
            </div>
          </div>
        </PopupContainer>
      </div>
    </>
  );
};

export default Datepicker;
