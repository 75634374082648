import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '../Paragraph';

export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  color: 'blue' | 'dark';
  id: string;
  label?: string;
  passwordValidation?: boolean;
  initialValue?: string;
  errorMessage?: string | string[];
  caption?: string | string[];
}

export const Input: React.FC<InputProps> = ({
  color,
  id,
  label,
  errorMessage,
  pattern,
  type,
  required,
  initialValue,
  passwordValidation,
  caption,
  disabled,
  onChange,
  ...rest
}: InputProps) => {
  const [validationError, setValidationError] = useState(errorMessage);
  const { t } = useTranslation();
  const styles = [];
  switch (color) {
    case 'blue':
      styles.push('border-blue-interactive');
      break;
    case 'dark':
      styles.push('border-grey-200');
      break;

    default:
      break;
  }

  const getErrorMessage = () => {
    if (required && type === 'email') {
      return t('VALIDATION_FIELD_EMAIL');
    } else if (passwordValidation) {
      return t('VALIDATION_FIELD_PASSWORD', {
        returnObjects: true,
      });
    } else {
      return t('VALIDATION_FIELD_EMPTY');
    }
  };

  const validateOnlyNumbers = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const allowedNonNumberKeys = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
      'Control',
      'Alt',
      'Meta',
      'Shift',
      'c',
      'v',
    ];

    if (!allowedNonNumberKeys.includes(event.key)) {
      const isNumberInput = !isNaN(parseInt(event.key));
      if (!isNumberInput) event.preventDefault();
      return isNumberInput;
    }
    return true;
  };

  const [hasFocus, setFocus] = useState(false);
  const [value, setValue] = useState<string>(initialValue ? initialValue : '');
  return (
    <>
      <div
        className={`outline relative border-b-2 ${disabled ? 'opacity-50' : ''}
        } ${validationError ? 'border-error' : styles.join(' ')}`}
      >
        <input
          id={id}
          {...rest}
          disabled={disabled}
          onChange={(event) => {
            setValue(event.target.value);
            if (onChange) onChange(event);
          }}
          value={value}
          pattern={
            !pattern && passwordValidation
              ? "^(?=.{6,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[$&+,:;=?@#|'<>.^*()%!-]).*$" // at least one digit, one uppercase char, one spechal char, 6 chars long
              : undefined
          }
          onFocus={() => {
            setValidationError('');
            setFocus(true);
          }}
          onBlur={() => setFocus(false)}
          onInvalid={(event) => {
            event.preventDefault();
            setValidationError(getErrorMessage());
          }}
          onKeyDown={(event) =>
            type === 'numbers' ? validateOnlyNumbers(event) : true
          }
          required={required}
          type={type}
          className="block text-base leading-5 font-sans p-1 w-full appearance-none focus:outline-none bg-transparent"
        />
        {label ? (
          <label
            htmlFor={id}
            className={`absolute top-0 text-lg m-1 duration-100 cursor-text origin-left ${
              hasFocus || (!hasFocus && value && value.length > 0)
                ? 'transform scale-75 -translate-y-4 cursor-auto'
                : ''
            }`}
          >
            <Paragraph
              level="body"
              text={label}
              color={
                hasFocus || (!hasFocus && value && value.length > 0)
                  ? 'light'
                  : 'dark'
              }
            />
          </label>
        ) : null}
      </div>

      {!validationError && caption && Array.isArray(caption) && (
        <ul className="mx-1 my-0.5 text-xxs leading-1 list-inside text-grey-200">
          {caption.map((item, index) => (
            <li key={index} className="list-disc">
              {item}
            </li>
          ))}
        </ul>
      )}

      {!validationError && caption && typeof caption === 'string' && (
        <div className="mx-1 my-0.5">
          <Paragraph level="small" text={caption} color="light" />
        </div>
      )}

      {Array.isArray(validationError) && (
        <ul className="mx-1 my-0.5 text-error text-xxs leading-1 list-inside">
          {validationError.map((item, index) => (
            <li key={index} className="list-disc">
              {item}
            </li>
          ))}
        </ul>
      )}

      {typeof validationError === 'string' && (
        <div className="mx-1 my-0.5">
          <Paragraph level="small" text={validationError} color="error" />
        </div>
      )}
    </>
  );
};
