import React from 'react';
import { useTranslation } from 'react-i18next';
import tiger from '../../images/tiger.jpg';
import { Paragraph } from '../Paragraph';

type TigaContainerProps = {
  children: React.ReactNode;
};

export const TigaContainer: React.FC<TigaContainerProps> = ({
  children,
}: TigaContainerProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-full h-full min-h-full-screen justify-center items-center bg-blue-primary">
      <div className="absolute inset-0 w-full h-full">
        <img src={tiger} className="w-full h-full object-cover" />
      </div>

      <div className="container p-0">
        <div className="col-12 md:col-8 flex items-center bg-white mx-auto">
          <div className="col-12 md:col-6 md:offset-3 flex flex-col justify-center min-h-full-screen md:min-h-auto pb-m md:py-xxxl">
            {children}
            <div className="flex justify-center mt-6">
              <a
                href="https://www.tigacall.de/imprint.html"
                target="_blank"
                rel="noreferrer"
              >
                <Paragraph
                  text={`© 2020 ${t('COPYRIGHT')}`}
                  color="light"
                  level="small"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
