import React, { useState } from 'react';

export type CheckboxProps = {
  children: React.ReactNode;
  value?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
export const Checkbox: React.FC<CheckboxProps> = ({
  children,
  value = false,
  onChange,
}: CheckboxProps) => {
  const [isChecked, setChecked] = useState(value);

  return (
    <label
      data-testid="checkbox"
      className="flex justify-start items-center select-none focus-within:bg-blue-500 w-fit-content cursor-pointer"
    >
      <div
        className={`border-2 w-4 h-4 ${
          isChecked ? 'border-blue-interactive' : 'border-grey-200'
        }  text-grey-200 fill-current flex flex-shrink-0 justify-center items-center mr-2 focus-within:text-blue-interactive focus-within:border-blue-interactive`}
      >
        <input
          type="checkbox"
          checked={isChecked}
          className="absolute opacity-0 cursor-pointer"
          onChange={(event) => {
            setChecked(event.target.checked);
            if (!event.target.checked) event.target.blur();
            if (onChange) onChange(event);
          }}
        />
        <div
          data-testid="checkmark"
          style={{ margin: '2px' }}
          className="checked fill-current hidden w-4 h-3 pointer-events-none bg-blue-interactive" //checked class is neeeded for additional css
        ></div>
      </div>
      <div className="mt-0.5">{children}</div>
    </label>
  );
};
