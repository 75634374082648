import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../Card';
import { Button } from '../Button';
import { Link } from '../Link';
import { Paragraph } from '../Paragraph';
import { routes } from '../../routes';
import { UserEventsData } from '../../services/dataService';

export interface SearchBarProps {
  users: UserEventsData[];
}

export const SearchBar: React.FC<SearchBarProps> = ({
  users,
}: SearchBarProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>('');
  const [hasFocus, setFocus] = useState(false);
  const searchBar = useRef<HTMLInputElement>(null);
  const [searchResults, setSearchResults] = useState(users);

  useEffect(() => {
    setSearchResults(
      users.filter((user) =>
        `${user.firstname} ${user.lastname}`
          .toLowerCase()
          .includes(value.toLowerCase()),
      ),
    );
  }, [value]);

  return (
    <div className="searchbar relative no-padding z-20">
      <Card>
        <div className="w-full relative">
          <input
            className="searchbar w-full bg-transparent focus:outline-none p-3"
            type="search"
            name="searchbar"
            autoComplete="off"
            value={value}
            ref={searchBar}
            placeholder={t('SEARCHBAR_PLACEHOLDER')}
            onFocus={() => {
              setFocus(true);
            }}
            onChange={(event) => {
              setValue(event.target.value);
            }}
          ></input>
          {hasFocus && value !== '' ? (
            <div className="absolute top-0 right-0 mt-2 mr-0">
              <Button
                level="icon"
                icon={'Close'}
                iconColor="light"
                iconWidth={24}
                onClick={() => {
                  setValue('');
                  setFocus(false);
                }}
              />
            </div>
          ) : (
            <div className="searchIcon absolute top-0 right-0 mt-2 mr-0">
              <Button
                level="icon"
                icon={'Search'}
                iconColor="light"
                iconWidth={20}
                onClick={() => {
                  searchBar?.current?.focus();
                  setFocus(true);
                }}
              />
            </div>
          )}
        </div>

        {/* User Suggestions */}
        <div
          className={`${
            value !== '' ? 'block' : 'hidden'
          } absolute w-full h-auto bg-white -mt-1 shadow-mBottom`}
        >
          <hr className="text-grey-200 mx-3" />
          {searchResults.length > 0 ? (
            <ul className="p-3">
              {searchResults.map(({ firstname, lastname, user_id }, index) => (
                <li className="mb-3 last:mb-0" key={index}>
                  <Link to={`${routes.dashboard.path}?userid=${user_id}`}>
                    <Paragraph
                      level="body-link"
                      color="blue"
                      text={`${firstname} ${lastname}`}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <div className="p-3">
              <Paragraph level="body" color="light" text={t('NO_ENTRIES')} />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};
