import React, { useState } from 'react';
import { Paragraph } from '../Paragraph';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';

export type TooltipProps = {
  tooltip: string;
};

export const Tooltip: React.FC<TooltipProps> = ({ tooltip }: TooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div
        className="tooltip-button z-10"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <div className="tooltip-icon">
          <Button
            aria-label={t('TOOLTIP_ARIA')}
            level="icon"
            icon="Tooltip"
            iconColor="light"
          />
        </div>
        <div
          className={`${
            showTooltip ? 'visible' : 'invisible'
          } tooltip absolute mt-0.5 left-0 h-auto px-3 py-4 text-center bg-blue-primary shadow-m z-50 whitespace-pre-wrap md:rigth-0 md:w-240px md:mt-0.5 md:-mr-10`}
          style={{ width: '200px', marginLeft: '-76px' }}
        >
          <Paragraph level="small" color="white" text={tooltip} />
        </div>
      </div>
    </>
  );
};
