import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion } from '../../components/Accordion';
import { Chart } from '../../components/Chart';
import { Content } from '../../components/Content';
import { Datepicker } from '../../components/Datepicker';
import { Footer } from '../../components/Footer';
import { Button } from '../../components/Button';
import { Link } from '../../components/Link';
import { PopupContainer } from '../../components/PopupContainer';
import { Headline } from '../../components/Headline';
import { Navigation } from '../../components/Navigation';
import { Paragraph } from '../../components/Paragraph';
import { Head, Table, TableLayout, TableRow } from '../../components/Table';
import {
  EventDataPoint,
  getUserEvents,
  removeTigaFromFamily,
  UserEventsData,
} from '../../services/dataService';
import { colors } from '../../variables';
import { SearchBar } from '../../components/SearchBar';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';
import { routes } from '../../routes';
import { useGlobalState } from '../../State';
import { ToastMessage } from '../../components/ToastMessage';
import {
  toastMessageDataFetchError,
  toastMessageInviteAccepted,
  toastMessageInviteDeclined,
  toastMessageRemoveTigaError,
  toastMessageRemoveTigaSuccess,
} from './DashboardToastMessage';
import { DateRange } from '../../components/Datepicker/Datepicker';

// ISO strings are the format the chart library requires
const convertChartDatesToISOStrings: (
  datapoints: EventDataPoint[],
) => EventDataPoint[] = (datapoints) =>
  datapoints.map(({ t, y }) => ({
    t: new Date(t).toISOString(),
    y,
  }));

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const [{ user }] = useGlobalState();
  const location = useLocation();
  const queryParams = parse(location.search.replace('?', ''));

  const [showTigaRemovePopup, setShowTigaRemovePopup] = useState(false);
  const [removeTigaId, setRemoveTigaId] = useState<string | null>(null);
  const [familyUsers, setFamiliyUsers] = useState<UserEventsData[]>();
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>({
    startDate: moment().subtract(7, 'd').toDate(),
    endDate: moment().toDate(),
  });

  const [toastMessage, setToastMessage] = useState(toastMessageDataFetchError);
  const [showToastMessage, setShowToastMessage] = useState(false);

  const [rootUserId, setRootUserId] = useState(user?.id); // the owner of the currently viewed dashboard

  const tableHeads: Head[] = [
    {
      text: t('NAME'),
      tooltip: '',
    },
    {
      text: t('SELECTION'),
      tooltip: t('TOOLTIP_SELECTION'),
    },
    {
      text: t('CALLS'),
      tooltip: t('TOOLTIP_CALLS'),
    },
    {
      text: t('CONTACTS_REACHED'),
      tooltip: t('TOOLTIP_CONTACTS_REACHED'),
    },
    {
      text: t('APPOINTMENTS_PLANNED'),
      tooltip: t('TOOLTIP_APPOINTMENTS_PLANNED'),
    },
    {
      text: t('APPOINTMENTS'),
      tooltip: t('TOOLTIP_APPOINTMENTS'),
    },
    {
      text: t('SIGNINGS'),
      tooltip: t('TOOLTIP_SIGNINGS'),
    },
  ];

  const tableLayout: TableLayout = {
    xs: ['12', '6', '6', '6', '6', '6', '6'],
    md: ['3', '3', '3', '3', '3 md:offset-3', '3', '3'],
    lg: ['2', '1', '1', '2', '2 lg:offset-0', '2', '2'],
  };

  const getUserData = useCallback(async () => {
    try {
      const data = await getUserEvents(
        selectedDateRange,
        queryParams.userid && typeof queryParams.userid === 'string'
          ? queryParams.userid
          : undefined,
      );

      const users = data.map((user: UserEventsData) => {
        user.appointments = convertChartDatesToISOStrings(user.appointments);
        user.calls = convertChartDatesToISOStrings(user.calls);

        return user;
      });

      const familyHeadId = rootUserId?.split(',').slice(-1);
      const rootUser = users.find(
        (familyUser) =>
          familyUser.user_id === (familyHeadId ? familyHeadId[0] : ''),
      );

      const otherUsers = users.filter(
        (familyUser) => familyUser.user_id !== rootUserId,
      );

      setFamiliyUsers(
        rootUser
          ? [
              rootUser,
              ...otherUsers.filter(
                (user) =>
                  user.user_id !== (familyHeadId ? familyHeadId[0] : ''),
              ),
            ]
          : otherUsers,
      ); // root user first
    } catch (error) {
      console.error(error);

      setToastMessage(toastMessageDataFetchError);
      setShowToastMessage(true);
    }
  }, [
    rootUserId,
    selectedDateRange,
    queryParams,
    setFamiliyUsers,
    setToastMessage,
    setShowToastMessage,
    routes,
  ]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setRootUserId(
      queryParams && typeof queryParams.userid === 'string'
        ? queryParams.userid
        : user?.id,
    );

    if (typeof queryParams.inviteAccepted !== 'undefined') {
      if (queryParams.inviteAccepted) {
        setToastMessage(toastMessageInviteAccepted);
        setShowToastMessage(true);
      } else {
        setToastMessage(toastMessageInviteDeclined);
        setShowToastMessage(true);
      }
    }
  }, [queryParams.userid, queryParams.inviteAccepted, user]);

  useEffect(() => {
    getUserData();
  }, [rootUserId, selectedDateRange]);

  const computeQuota: (
    dividend: number,
    divisor: number,
  ) => number | undefined = (dividend, divisor) => {
    return divisor ? Math.round((dividend / divisor) * 1000) / 10 : undefined;
  };

  return (
    <>
      <Navigation />
      <Content>
        <div className="container">
          <div className="mb-3">
            <Headline
              level="h1"
              color="blue"
              text={t('TIGA_FAMILY_HEADLINE')}
            />
          </div>
          <div className="justify-between row md:mb-6">
            <div className="col-12 md:col-6">
              {familyUsers && familyUsers.length > 1 && (
                <SearchBar users={familyUsers} />
              )}
            </div>
            <div className="flex justify-end col-12 md:col-6 mt-xl md:mt-0">
              <Datepicker
                initialStartDate={moment().subtract(7, 'd').toDate()}
                initialEndDate={new Date()}
                onSelectionChange={(dateRange) =>
                  setSelectedDateRange(dateRange)
                }
              />
            </div>
          </div>
          {queryParams.userid && (
            <div className="flex space-x-s">
              <a
                onClick={(event) => {
                  event.preventDefault();
                  history.back();
                }}
              >
                <Paragraph level="link" color="blue" text="< Zurück" />
              </a>
              <Link to={routes.dashboard.path}>
                <Paragraph
                  level="link"
                  color="blue"
                  text="<< Zurück zur Übersicht"
                />
              </Link>
            </div>
          )}
          <Table heads={tableHeads} layout={tableLayout}>
            {familyUsers &&
              familyUsers.map((familyUser, index) => {
                const rowData = [
                  {
                    // Name
                    value: `${familyUser.firstname} ${familyUser.lastname}`,
                    quota: undefined,
                  },
                  {
                    // Selection
                    value: familyUser.contacts_selected ?? 0,
                    quota: undefined,
                  },
                  {
                    // Calls
                    value: familyUser.call_attempt_total,
                    quota: computeQuota(
                      familyUser.call_attempt_total,
                      10 *
                        (moment(selectedDateRange.endDate).dayOfYear() -
                          moment(selectedDateRange.startDate).dayOfYear()),
                    ),
                  },
                  {
                    // Contacts reached
                    value: familyUser.call_success_total,
                    quota: computeQuota(
                      familyUser.call_success_total,
                      familyUser.call_attempt_total,
                    ),
                  },
                  {
                    // Planned Appointments
                    value: familyUser.appointments_planned_total,
                    quota: computeQuota(
                      familyUser.appointments_planned_total,
                      familyUser.call_success_total,
                    ),
                  },
                  {
                    // Appointments
                    value: familyUser.appointments_total,
                    quota: undefined,
                  },
                  {
                    // Sales
                    value: familyUser.sales_total,
                    quota: computeQuota(
                      familyUser.sales_total,
                      familyUser.call_success_total,
                    ),
                  },
                ];

                const isRootUser =
                  user &&
                  // is other user specified with queryparam userid
                  ((queryParams &&
                    typeof queryParams.userid === 'string' &&
                    queryParams.userid.indexOf(familyUser.user_id) > -1) ||
                    // is user himself
                    (!(queryParams && queryParams.userid) &&
                      user.id === familyUser.user_id));

                return (
                  <div
                    key={index}
                    className="pb-1 mx-s md:pb-0 border-b-1 last:border-0 border-grey-200"
                  >
                    <Accordion
                      isInitiallyCollapsed={!isRootUser}
                      trigger={
                        <TableRow layout={tableLayout} heads={tableHeads}>
                          {rowData.map(({ value, quota }, index) => (
                            <div key={index}>
                              <div
                                className={
                                  index === 0 ? 'flex items-center' : ''
                                }
                              >
                                {!isRootUser && index === 0 ? (
                                  <Link
                                    className="w-full"
                                    to={`${routes.dashboard.path}?userid=${[
                                      rootUserId,
                                      familyUser.user_id,
                                    ]
                                      .filter((id) => user && id !== user.id)
                                      .join()}`}
                                  >
                                    <div className="flex md:justify-between">
                                      <Paragraph
                                        color="blue"
                                        level="body-link"
                                        text={value.toString()}
                                      />
                                      {!isRootUser && index === 0 ? (
                                        <div className="w-4 h-2 -mt-1">
                                          <Button
                                            level="icon"
                                            icon={'Delete'}
                                            iconColor="light"
                                            iconWidth={0}
                                            onClick={() => {
                                              setRemoveTigaId(
                                                familyUser.relation_id,
                                              );
                                              setShowTigaRemovePopup(true);
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                    {familyUser.family_count ? (
                                      <Paragraph
                                        color="light"
                                        level="small"
                                        text={`+ ${
                                          familyUser.family_count
                                        } Tiga${
                                          familyUser.family_count > 1 ? 's' : ''
                                        }`}
                                      />
                                    ) : null}
                                  </Link>
                                ) : (
                                  <Paragraph
                                    color="dark"
                                    level="body"
                                    text={value.toString()}
                                  />
                                )}
                              </div>
                              {quota ? (
                                <Paragraph
                                  color="light"
                                  level="small"
                                  text={quota ? `${quota} %` : ''}
                                />
                              ) : null}
                            </div>
                          ))}
                        </TableRow>
                      }
                      collapsable={
                        <div className="col-12 mb-s">
                          <Chart
                            datasets={[
                              {
                                label: t('CALLS'),
                                color: colors['blue-interactive'],
                                hoverColor: colors.orange,
                                data: familyUser.calls,
                              },
                              {
                                label: t('APPOINTMENTS'),
                                color: colors.orange,
                                hoverColor: colors.orange,
                                data: familyUser.appointments,
                              },
                            ]}
                          />
                        </div>
                      }
                    />
                  </div>
                );
              })}
          </Table>
          <div className="row">
            <div className="flex justify-end col-12">
              <div className="flex block w-6 h-6 overflow-hidden rounded-full shadow-l">
                <Button
                  level="icon-round"
                  icon={'BackToTop'}
                  iconColor="blue"
                  iconWidth={40}
                  onClick={() => {
                    scrollToTop();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Content>

      <ToastMessage
        isVisible={showToastMessage}
        type={t(toastMessage.type)}
        headline={t(toastMessage.headline)}
        text={t(toastMessage.text)}
        onToggle={() => {
          setShowToastMessage(false);
        }}
        timeout={true}
      />

      {/* Delete PopUp */}
      <PopupContainer
        open={showTigaRemovePopup}
        onBlur={() => setShowTigaRemovePopup(false)}
      >
        <div className="w-full p-6">
          <Headline
            level="h3"
            color="blue"
            text={t('REMOVE_TIGA_POPUP_HEAD')}
          />
          <div className="my-4">
            <Paragraph
              level="body"
              color="dark"
              text={t('REMOVE_TIGA_POPUP_BODY')}
            />
          </div>
          <div className="flex justify-end">
            <div className="flex mt-4">
              <div className="mr-4">
                <Button
                  type="reset"
                  level="secondary"
                  text={t('CANCEL')}
                  onClick={() => {
                    setRemoveTigaId(null);
                    setShowTigaRemovePopup(false);
                  }}
                />
              </div>
              <Button
                level="primary"
                type="submit"
                text={t('REMOVE')}
                onClick={async () => {
                  try {
                    if (removeTigaId) {
                      await removeTigaFromFamily(removeTigaId);
                      setToastMessage(toastMessageRemoveTigaSuccess);
                      getUserData();
                    } else {
                      setToastMessage(toastMessageRemoveTigaError);
                    }
                  } catch (error) {
                    console.error(error);
                    setToastMessage(toastMessageRemoveTigaError);
                  }
                  setShowToastMessage(true);
                  setRemoveTigaId(null);
                  setShowTigaRemovePopup(false);
                }}
              />
            </div>
          </div>
        </div>
      </PopupContainer>
      <Footer />
    </>
  );
};
