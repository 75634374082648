import React from 'react';
import { getBreakpointClasses, TableProps } from '.';
import { Paragraph } from '../Paragraph';
import { Tooltip } from '../Tooltip';

interface TableRowProps extends TableProps {
  divider?: boolean;
}
export const TableRow: React.FC<TableRowProps> = ({
  children,
  layout,
  heads,
  divider = false,
}: TableRowProps) => {
  return (
    <div className="px-l">
      <div
        className={`row mt-l py-l md:mt-0 md:py-m ${
          divider ? 'border-b-1 border-grey-200' : ''
        }`}
      >
        {React.Children.map(children, (cell, index) => (
          <div
            key={index}
            className={`${
              index === 0 ? 'lg:text-left' : 'lg:text-right'
            } mb-m lg:mb-0 ${
              layout ? getBreakpointClasses(index, layout) : null
            }`}
          >
            {heads ? (
              <div className="lg:hidden flex">
                <Paragraph
                  color="dark"
                  level="small"
                  text={heads[index].text}
                />
                <div
                  key={index}
                  className={`${
                    index === 0 ? 'hidden' : 'block'
                  } -mt-1.5 -ml-1 tooltip-button relative`}
                >
                  <Tooltip tooltip={heads[index].tooltip} />
                </div>
              </div>
            ) : null}
            {cell}
          </div>
        ))}
      </div>
    </div>
  );
};
