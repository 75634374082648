import React from 'react';
import { Icon } from '../Icon';
import { iconColors, iconNames } from '../Icon/Icon';
export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  level: 'primary' | 'icon' | 'secondary' | 'icon-round';
  text?: string;
  icon?: typeof iconNames[number];
  iconColor?: iconColors;
  iconWidth?: number;
}

export const Button: React.FC<ButtonProps> = ({
  level = 'primary',
  text,
  icon,
  iconColor = 'white',
  iconWidth = 32,
  ...rest
}: ButtonProps) => {
  const styles = [];
  switch (level) {
    case 'primary':
      styles.push(
        'bg-blue-interactive',
        'text-white',
        'p-2',
        'hover:bg-orange transition-colors',
        'disabled:bg-blue-interactive',
        'min-w-xl',
      );
      break;
    case 'secondary':
      styles.push(
        'text-bg-blue-interactive',
        'p-2',
        'hover:bg-orange transition-colors hover:text-white',
        'disabled:bg-blue-interactive',
        'min-w-xl',
      );
      break;
    case 'icon':
      styles.push('w-4 h-4 min-w-xl');
      break;
    case 'icon-round':
      styles.push(
        'text-bg-blue-interactive',
        'hover:bg-blue-interactive transition-colors hover:text-white',
        'w-l',
        'min-w-l',
      );
      break;

    default:
      break;
  }
  return (
    <button
      className={`flex items-center justify-center w-full h-auto outline-none min-h-l focus:outline-none disabled:opacity-50 disabled:cursor-default ${styles.join(
        ' ',
      )}`}
      {...rest}
    >
      {icon ? (
        <Icon name={icon} color={iconColor} hover={true} width={iconWidth} />
      ) : (
        <p
          className="text-base 
          font-black 
          uppercase 
          tracking-tight text-inherit"
        >
          {text ? text : ''}
        </p>
      )}
    </button>
  );
};
