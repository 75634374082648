import React, { useEffect, useState } from 'react';
import { Link } from '../../components/Link';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { Headline } from '../../components/Headline';
import { Input } from '../../components/Input';
import { routes } from '../../routes';
import { Paragraph } from '../../components/Paragraph';
import { ToastMessage } from '../../components/ToastMessage';
import { Icon } from '../../components/Icon/Icon';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TigaContainer } from '../../components/TigaContainer';
import {
  confirmSignUp,
  resendConfirmSignUpCode,
  signin,
  updateUser,
} from '../../services/authService';
import { useGlobalState } from '../../State';
import { MultiStepForm } from '../../components/MultiStepForm';
import i18n from '../../i18n';

export const Signin: React.FC = () => {
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [staySignedIn, setStaySignedIn] = useState<boolean>(false);
  const [signinError, setSigninError] = useState<string>('');
  const [showClearPassword, setShowClearPassword] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const [{ user }, { setUser }] = useGlobalState();
  const [showToast, setShowToast] = useState(false);
  const [currentFormStep, setCurrentFormStep] = useState(0);

  const requestNewAuthenticationCode = async (email: string) => {
    try {
      await resendConfirmSignUpCode(email);
      setCurrentFormStep(currentFormStep + 1);
    } catch (error) {
      setShowToast(true);
      setSigninError(t(error.message));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (email && password) {
      const signinData = {
        email: email,
        password: password,
        staySignedIn: staySignedIn,
      };
      try {
        const user = await signin(signinData);
        if (user.locale) {
          i18n.changeLanguage(user.locale);
        }
        setUser(user);

        if (user.staySignedIn !== staySignedIn) {
          const newUser = { ...user, staySignedIn };
          await updateUser(newUser);
          setUser(newUser);
        }

        history.push(routes.dashboard.path);
      } catch (error) {
        if (error.message === 'UserNotConfirmedException') {
          requestNewAuthenticationCode(email);
        } else {
          setShowToast(true);
          console.error(error);
          setSigninError(error.message);
        }
      }
    }
  };

  const handleConfirmSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    if (email && password && code) {
      try {
        await confirmSignUp(email, code);
      } catch (error) {
        setSigninError(error.message);
        setShowToast(true);
      }
      try {
        const authenticatedUser = await signin({
          email: email,
          password: password,
          staySignedIn: staySignedIn,
        });
        setUser(authenticatedUser);
      } catch (error) {
        setSigninError(error.message);
        setShowToast(true);
        setCurrentFormStep(0);
      }
    } else {
      setCurrentFormStep(0);
    }
  };

  useEffect(() => {
    // redirect only after global user is set
    if (user) history.push(routes.paymentOptions.path);
  }, [user]);

  return (
    <>
      <TigaContainer>
        <div className="my-120px md:my-0">
          <div className="w-120px md:w-240px block m-auto my-s">
            <Icon name="Logo" color="blue-primary" />
          </div>
          <div className="text-center">
            <Headline level="h2" color="blue" text={t(`SIGNIN`)} />
          </div>
        </div>
        <div>
          <MultiStepForm
            currentStep={currentFormStep}
            steps={[
              <>
                <form key={1} onSubmit={handleSubmit}>
                  <div className="mt-6">
                    <Input
                      color="blue"
                      type="email"
                      label={t(`EMAIL`)}
                      id="signin_email"
                      autoComplete="email"
                      onChange={(event) => setEmail(event.target.value)}
                      required
                    />
                  </div>
                  <div className="mt-3 relative">
                    <Input
                      color="blue"
                      type={showClearPassword ? 'text' : 'password'}
                      label={t('PASSWORD')}
                      id="signin_password"
                      autoComplete="current-password"
                      onChange={(event) => setPassword(event.target.value)}
                      required
                    />
                    <div className="absolute top-0.5 right-l">
                      <Button
                        tabIndex={-1}
                        level="icon"
                        type="button"
                        icon={
                          showClearPassword ? 'PasswordInvisible' : 'Password'
                        }
                        iconColor="light"
                        onClick={() => setShowClearPassword(!showClearPassword)}
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <Checkbox
                      onChange={(event) =>
                        setStaySignedIn(event.target.checked)
                      }
                    >
                      <Paragraph
                        text={t('STAY_SIGNEDIN')}
                        color="dark"
                        level="body"
                      />
                    </Checkbox>
                  </div>
                  <div className="mt-5">
                    <Button level="primary" text={t(`SIGNIN`)} type="submit" />
                  </div>
                </form>
                <div className="row my-xs">
                  <div className="col-12 md:col-6 text-center md:text-left my-xs md:m-0">
                    <Link to={routes.resetPassword.path}>
                      <Paragraph
                        level="link"
                        color="blue"
                        text={t(`PASSWORD_FORGOT_CTA`)}
                      />
                    </Link>
                  </div>
                  <div className="col-12 md:col-6 text-center md:text-right my-xs md:m-0">
                    <Link to={routes.signup.path}>
                      <Paragraph
                        level="link"
                        color="blue"
                        text={t(`SIGNUP_CTA`)}
                      />
                    </Link>
                  </div>
                </div>
              </>,
              <>
                <form
                  key={2}
                  className="space-y-4"
                  onSubmit={handleConfirmSubmit}
                >
                  {/* step 2 */}
                  <div className="row mt-6 whitespace-pre-wrap">
                    <div className="col-12">
                      <div className="mb-6">{t('RESET_CODE_EXPLANATION')}</div>
                      <Input
                        color="blue"
                        type="numbers"
                        label={t('RESET_CODE')}
                        id="resetPin"
                        maxLength={6}
                        autoComplete="email"
                        onChange={(event) => setCode(event.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mt-5">
                    <Button level="primary" text={t('CONFIRM')} type="submit" />
                  </div>
                </form>
                <div className="row my-xs">
                  <div className="col-12 md:col-6 text-center md:text-left my-xs md:m-0">
                    <button onClick={() => setCurrentFormStep(0)}>
                      <Paragraph
                        level="link"
                        color="blue"
                        text={t(`SIGNUP_BACK`)}
                      />
                    </button>
                  </div>
                </div>
              </>,
            ]}
          />
        </div>
      </TigaContainer>
      <ToastMessage
        isVisible={showToast}
        type="error"
        headline={t('SIGNIN_ERROR_HEADLINE')}
        text={t(signinError)}
        onToggle={(state) => {
          setShowToast(state);
        }}
      ></ToastMessage>
    </>
  );
};
