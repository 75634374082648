import React from 'react';
import { spacings } from '../../variables';

type ContentProps = {
  children: React.ReactNode;
};

export const Content: React.FC<ContentProps> = ({ children }: ContentProps) => {
  // It would be better if these wouldn't be hardcoded
  const headerHeight = spacings.xxxl * 2;
  const footerHeight = 244;

  return (
    <div
      style={{
        minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px)`,
      }}
    >
      {children}
    </div>
  );
};
