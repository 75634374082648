import React, { useLayoutEffect, useRef, useState } from 'react';
import { spacings } from '../../variables';
import { Button } from '../Button';

export type AccordionProps = {
  trigger: React.ReactNode;
  collapsable: React.ReactNode;
  isInitiallyCollapsed?: boolean;
};

export const Accordion: React.FC<AccordionProps> = ({
  trigger,
  collapsable,
  isInitiallyCollapsed = false,
}: AccordionProps) => {
  const [isCollapsed, setisCollapsed] = useState(isInitiallyCollapsed);
  const [collapsableHeight, setCollapsableHeight] = useState<null | number>(
    null,
  );
  const collapsableRef = useRef<null | HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (collapsableRef.current) {
      setCollapsableHeight(collapsableRef.current.clientHeight + spacings.m);
    }
  }, [collapsableRef]);

  return (
    <>
      <div className="row">
        <div className="col-12 md:col-11">{trigger}</div>
        <div className="md:col-1 hidden md:block">
          <div className="w-full h-full flex justify-center items-center">
            <div
              className={`flex justify-center items-center w-6 h-6 mr-m rounded-full origin-center transform duration-200 transition-all hover:bg-grey-200 hover:bg-opacity-20 ${
                isCollapsed ? '-rotate-0' : 'rotate-180'
              }`}
            >
              <Button
                data-testid="toggle"
                level="icon"
                icon={'ArrowSmall'}
                iconColor="light"
                iconWidth={12}
                onClick={() => setisCollapsed(!isCollapsed)}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        ref={collapsableRef}
        data-testid={isCollapsed ? 'collapsed' : 'open'}
        className="overflow-hidden transition-all duration-300"
        style={{
          height: `${
            collapsableHeight
              ? `${isCollapsed ? 0 : collapsableHeight}px`
              : 'auto'
          }`,
        }}
      >
        {collapsable}
      </div>
      <div className="w-full flex justify-center md:hidden">
        <div
          className={`mr-m transform duration-200 w-xl ${
            isCollapsed ? 'rotate-0' : '-rotate-180'
          }`}
        >
          <Button
            level="icon"
            icon={'ArrowSmall'}
            iconColor="dark"
            iconWidth={12}
            onClick={() => setisCollapsed(!isCollapsed)}
          />
        </div>
      </div>
    </>
  );
};
