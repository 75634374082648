import React, { useEffect, useMemo, useState } from 'react';

export interface CardProps {
  clickable?: boolean;
  children?: React.ReactNode;
  value?: boolean;
  onClick?: (isSelected: boolean) => void;
}
export const Card: React.FC<CardProps> = ({
  clickable,
  children,
  value = false,
  onClick,
}: CardProps) => {
  const [isSelected, setSelected] = useState(value);

  const classes = useMemo(
    () =>
      `w-full h-full bg-white shadow-m ${
        isSelected ? 'selected bg-blue-interactive' : 'not-selected bg-white' //selected and not-selected are used as identifiers for testing
      }`,
    [isSelected],
  );

  useEffect(() => {
    if (value === false) {
      setSelected(value);
    }
  }, [value]);

  return clickable ? (
    <button
      data-testid="card-clickable"
      className={classes}
      onClick={() => {
        setSelected(!isSelected);
        if (onClick) onClick(!isSelected);
      }}
    >
      {children}
    </button>
  ) : (
    <div data-testid="card-static" className={classes}>
      {children}
    </div>
  );
};
