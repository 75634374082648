import React from 'react';

type MultiStepFormProps = {
  steps: React.ReactNode[];
  currentStep: number;
};

export const MultiStepForm: React.FC<MultiStepFormProps> = ({
  steps,
  currentStep,
}: MultiStepFormProps) => {
  return (
    <div className="relative">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`${
            index === currentStep ? 'relativ' : 'absolute hidden inset-0'
          }`}
        >
          {step}
        </div>
      ))}
    </div>
  );
};
