import React, { useState, useEffect } from 'react';
import { Icon } from '../Icon';
import { Paragraph } from '../Paragraph';
import { Button } from '../Button';

export type ToastMessageData = {
  type: 'success' | 'error';
  headline: string;
  text: string;
};
interface ToastMessageOptions {
  isVisible: boolean;
  onToggle: (state: boolean) => void;
  timeout?: boolean;
}

export type ToastMessageProps = ToastMessageData & ToastMessageOptions;

export const ToastMessage: React.FC<ToastMessageProps> = ({
  type,
  headline,
  text,
  isVisible,
  onToggle,
  timeout,
}: ToastMessageProps) => {
  const [toastVisibility, setToastVisibility] = useState(isVisible);
  const [fadeOut, setFadeOut] = useState(false);
  const [toastTimeout, setToastTimeout] = useState<number | null>(null);
  const [fadeTimeout, setFadeTimeout] = useState<number | null>(null);
  const [fadeOutTimeout, setFadeOutTimeout] = useState<number | null>(null);

  useEffect(() => {
    setToastVisibility(isVisible);
    if (isVisible && timeout) {
      setFadeTimeout(window.setTimeout(() => setFadeOut(true), 4500));
      setToastTimeout(window.setTimeout(() => onToggle(false), 5000));
      setFadeOutTimeout(window.setTimeout(() => setFadeOut(false), 5000));
    }
  }, [isVisible]);

  return (
    <div
      className={`z-10 ${toastVisibility ? 'visible fadein' : 'invisible'} ${
        fadeOut ? 'fadeout' : ''
      } fixed bottom-0 w-full px-5 text-left md:px-0 md:inset-x-1/2 md:w-2/5 md:transform md:-translate-x-1/2`}
    >
      <div
        className={`${
          type === 'success' ? 'border-success' : 'border-error'
        } mb-6 bg-white bg-opacity-90 border-1 border-l-8 shadow-m`}
      >
        <div className="flex items-center p-4 pr-6">
          <div className="mr-4">
            {type === 'success' ? (
              <Icon name="Success" color="success" width={32} height={32} />
            ) : (
              <Icon name="Error" color="error" width={32} height={32} />
            )}
          </div>
          <div>
            <div className="mb-2">
              <Paragraph color="dark" level="body" text={headline} />
            </div>
            <Paragraph color="dark" level="small" text={text} />
          </div>
        </div>
        <div className="absolute top-0 right-0 mt-2 mr-5 md:mr-0">
          <Button
            level="icon"
            icon={'CloseSmall'}
            iconColor="dark"
            iconWidth={12}
            onClick={() => {
              onToggle(false);
              setFadeOut(false);
              if (toastTimeout) window.clearTimeout(toastTimeout);
              if (fadeTimeout) window.clearTimeout(fadeTimeout);
              if (fadeOutTimeout) window.clearTimeout(fadeOutTimeout);
            }}
          />
        </div>
      </div>
    </div>
  );
};
