import React, { useCallback, useEffect } from 'react';
import { Switch, RouteProps, Redirect, Route } from 'react-router-dom';
import { useGlobalState } from './State';
import { isUserSignedIn, signin } from './services/authService';
import { routes } from './routes';
interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType;
  condition:
    | 'authenticated'
    | '!authenticated'
    | 'paymentRequired'
    | 'checkoutComplete';
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  condition,
  path,
  ...rest
}: ProtectedRouteProps) => {
  const [{ user }] = useGlobalState();

  const routeComponent = useCallback(
    ({ ...routeProps }: RouteProps) => {
      switch (condition) {
        case 'paymentRequired':
          return user && !user.hasCompletedPayment ? (
            <Component {...rest} {...routeProps} />
          ) : (
            <Redirect to={routes.dashboard.path} />
          );

        case 'authenticated':
          if (user) {
            return user.hasCompletedPayment ? (
              <Component {...rest} {...routeProps} />
            ) : (
              <Redirect to={routes.paymentOptions.path} />
            );
          } else {
            return <Redirect to={routes.signin.path} />;
          }

        case '!authenticated':
          return !user ? (
            <Component {...rest} {...routeProps} />
          ) : (
            <Redirect to={routes.dashboard.path} />
          );
      }
    },
    [path, condition, routes],
  );

  return <Route {...rest} render={routeComponent} />;
};

export const Router: React.FC = () => {
  const [{ user }, { setUser }] = useGlobalState();

  // check if user is still signed in
  // resignin if staySignedIn flag is set
  useEffect(() => {
    (async () => {
      if (user) {
        const isStillSignedIn = await isUserSignedIn();
        if (!isStillSignedIn && user.isVerified) {
          if (user.staySignedIn) {
            signin(user);
          } else {
            setUser(null);
          }
        }
      }
    })();
  }, [user]);

  return (
    <Switch>
      <ProtectedRoute condition="paymentRequired" {...routes.paymentComplete} />
      <ProtectedRoute condition="!authenticated" {...routes.signin} />
      <ProtectedRoute condition="!authenticated" {...routes.signup} />
      <ProtectedRoute condition="!authenticated" {...routes.resetPassword} />
      <ProtectedRoute condition="paymentRequired" {...routes.paymentOptions} />
      <ProtectedRoute condition="authenticated" {...routes.profile} />
      <ProtectedRoute condition="authenticated" {...routes.dashboard} />
    </Switch>
  );
};
