import { ToastMessageData } from '../../components/ToastMessage/ToastMessage';

export const toastMessageDataFetchError: ToastMessageData = {
  type: 'error',
  headline: 'DASHBOARD_FETCH_DATA_ERROR_HEADLINE',
  text: 'DASHBOARD_FETCH_DATA_ERROR_TEXT',
};

export const toastMessageInviteAccepted: ToastMessageData = {
  type: 'success',
  headline: 'DASHBOARD_INVITE_ACCEPTED_HEADLINE',
  text: 'DASHBOARD_INVITE_ACCEPTED_TEXT',
};

export const toastMessageInviteDeclined: ToastMessageData = {
  type: 'error',
  headline: 'DASHBOARD_INVITE_DECLINED_HEADLINE',
  text: 'DASHBOARD_INVITE_DECLINED_TEXT',
};

export const toastMessageRemoveTigaSuccess: ToastMessageData = {
  type: 'success',
  headline: 'REMOVE_TIGA_SUCCESS_HEADLINE',
  text: 'REMOVE_TIGA_SUCCESS_TEXT',
};

export const toastMessageRemoveTigaError: ToastMessageData = {
  type: 'error',
  headline: 'REMOVE_TIGA_ERROR_HEADLINE',
  text: 'REMOVE_TIGA_ERROR_TEXT',
};
