import { ToastMessageData } from '../ToastMessage/ToastMessage';

export const toastMessageInviteSuccess: ToastMessageData = {
  type: 'success',
  headline: 'DASHBOARD_INVITE_SUCCESS_HEADLINE',
  text: 'DASHBOARD_INVITE_SUCCESS_TEXT',
};

export const toastMessageInviteError: ToastMessageData = {
  type: 'error',
  headline: 'DASHBOARD_INVITE_ERROR_HEADLINE',
  text: 'DASHBOARD_INVITE_ERROR_TEXT',
};

export const toastMessageInviteNotFoundError: ToastMessageData = {
  type: 'error',
  headline: 'DASHBOARD_INVITE_ERROR_HEADLINE',
  text: 'DASHBOARD_INVITE_NOTFOUND_TEXT',
};
